import React from 'react'
import HeaderWrapper from '../../helper/PageWrapper'

export default class Shop extends React.Component {
  render() {
    return (
      <HeaderWrapper headerTitle='Shop' linkTo='='>
        Content
      </HeaderWrapper>
    )
  }
}
