import React from 'react'
import PageWrapper from '../../helper/PageWrapper'
import i18n from 'i18next'
import { Link } from 'react-router-dom'
import './Menu.scss'

import calenderIcon from '../../img/icons/calendar.svg'
import cameraMonitorIcon from '../../img/icons/cameraMonitor.svg'
import accountIcon from '../../img/icons/account.svg'
import adManagementIcon from '../../img/icons/adManagement.svg'
import { Role } from '@soccerwatch/common'
import contractContainer from '../../services/contractContainer'

export type MenuProps = {
  openMenu: boolean
  setOpenMenu: (newValue: boolean) => void
}

type Card = {
  title: string
  pathname: string
  icon: string
  disabled?: boolean
}

export enum RouteNames {
  Login = 'Login',
  Claim = 'Claim',
  Support = 'Support',
  Tagging = 'Tagging',
  Trainer = 'trainer',
  RecordingPlan = 'RecordingPlan',
  CameraMonitor = 'CameraMonitor',
  Advertisement = 'Advertisement',
  Administration = 'Administration'
}

// * List of MenuItems that this Role should not be able to see and use
export const menuFilter: Record<string, string[]> = {
  [Role.clubTagger]: [RouteNames.CameraMonitor, RouteNames.Advertisement],
  [Role.adManager]: [RouteNames.CameraMonitor, RouteNames.RecordingPlan]
}

export const menuFilterAllow: Record<string, string[]> = {
  [Role.contractOwner]: [
    RouteNames.Login,
    RouteNames.Claim,
    RouteNames.Support,
    RouteNames.Tagging,
    RouteNames.RecordingPlan,
    RouteNames.CameraMonitor,
    RouteNames.Advertisement,
    RouteNames.Administration
  ],
  [Role.cameraController]: [
    RouteNames.Login,
    RouteNames.Claim,
    RouteNames.Support,
    RouteNames.Tagging,
    RouteNames.RecordingPlan,
    RouteNames.CameraMonitor,
    RouteNames.Advertisement,
    RouteNames.Administration
  ],
  [Role.clubTagger]: [
    RouteNames.Login,
    RouteNames.Claim,
    RouteNames.Support,
    RouteNames.RecordingPlan,
    RouteNames.Tagging,
    RouteNames.CameraMonitor
  ],
  [Role.adManager]: [
    RouteNames.Login,
    RouteNames.Claim,
    RouteNames.Support,
    RouteNames.Advertisement,
    RouteNames.CameraMonitor
  ],
  [Role.user]: [RouteNames.Login, RouteNames.Claim, RouteNames.CameraMonitor],
  [Role.trainer]: [RouteNames.Login, RouteNames.Claim, RouteNames.Trainer, RouteNames.CameraMonitor]
}

// * If user tries to Navigate a Route is is RoleFiltered for his role, where to redirect
export const rerouteOptions: Record<string, string> = {
  [Role.trainer]: '/login',
  [Role.clubTagger]: '/recordingplan',
  [Role.adManager]: '/advertisement',
  [Role.user]: '/claim/camera'
}

export const getHighestRelevantRoles = () => {
  const roles = contractContainer.getHighestRelevantRolesForCurrentContract()
  const roleFilter: string[] = []

  roles.map((role) => {
    const newFilter = menuFilterAllow[role]
    newFilter.map((string) => {
      if (roleFilter.includes(string)) {
        return null
      } else {
        roleFilter.push(string)
      }
    })
  })

  return roleFilter
}

export default class Menu extends React.Component<MenuProps> {
  state = {
    links: [
      {
        title: i18n.t('home.buttonTitle.recordingplan'),
        pathname: '/recordingplan',
        icon: calenderIcon,
        name: RouteNames.RecordingPlan
      },
      {
        title: i18n.t('home.buttonTitle.cameramonitor'),
        pathname: '/cameramonitor',
        icon: cameraMonitorIcon,
        name: RouteNames.CameraMonitor
      },
      {
        title: i18n.t('home.buttonTitle.adManagement'),
        pathname: '/advertisement',
        icon: adManagementIcon,
        name: RouteNames.Advertisement
      },
      {
        title: i18n.t('home.buttonTitle.administration'),
        pathname: '/administration',
        icon: accountIcon,
        name: RouteNames.Administration
      },
      {
        title: i18n.t('home.buttonTitle.logout'),
        pathname: '/login',
        icon: accountIcon,
        name: RouteNames.Login
      }
    ].map((item, index) => ({ ...item, id: index + 1 }))
  }

  renderCard(card: Card, index: number) {
    const checkActive = location.pathname.search(card.pathname) >= 0
    return (
      <div className='mbsc-col-6 squareWrapper' key={index}>
        <Link
          to={card.disabled ? '#' : card.pathname}
          onClick={() => this.props.setOpenMenu(card.disabled ?? false)}
        >
          <div className='square' style={{ margin: '4px' }}>
            <div className={checkActive ? 'content activeContent' : 'content'}>
              <div className='innerContent'>
                <img src={card.icon} />
                <div className='menuText'>{card.title}</div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    )
  }

  render() {
    const roleFilter = getHighestRelevantRoles()

    return (
      <PageWrapper headerTitle='Home' className={'home'}>
        <div style={{ marginTop: '-8px' }}>
          <h2>{i18n.t('home.title')}</h2>
        </div>
        <div className='mbsc-grid mbsc-no-padding'>
          <div className='mbsc-row mbsc-align-center'>
            {this.state.links.map((card, index) => {
              if (roleFilter && roleFilter.includes(card.name)) {
                return this.renderCard(card, index)
              }
              return null
            })}
          </div>
        </div>
      </PageWrapper>
    )
  }
}
