import unstated, { Container } from 'unstated-typescript'
import React from 'react'

/**
 * Takes a container instance and a key and returns a new component, that will
 * receive the container as a prop under the given key. Essentially, this is
 * just a wrapper for the unstated function that a) performs the key -> container
 * mapping and b) provides better types.
 * @param container - An instance of the container that should be injected into the component
 * @param key - The key that the container should be injected under
 * (-> this.props[key] will be the injected instance of the container)
 */
export const withContainer =
  <ContainerType extends Container<Record<string, unknown>>, Key extends string>(
    container: ContainerType,
    key: Key
  ) =>
  <P extends Record<Key, ContainerType>>(component: React.ComponentType<P> | React.FunctionComponent<P>) =>
    unstated(container, (containerInstance) => ({ [key]: containerInstance }))(
      component
    ) as React.ComponentType<Omit<P, Key>>
