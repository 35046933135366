import React, { createRef } from 'react'
import StaigeButton from '../../../components/styles/Button'
import i18n from '../../../languages/i18n'
import iconChevronLeft from '../../../img/icons/chevron_left.svg'
import iconChevronRight from '../../../img/icons/chevron_right.svg'

import { isMobile } from 'react-device-detect'

import './tipps.scss'

type TippsStateType = {
  tipps: Array<{ text: string; onlyMobile?: boolean }>
}

export default class Tipps extends React.Component<unknown, TippsStateType> {
  scrollWrapperRef = createRef<HTMLDivElement>()

  constructor(props: unknown) {
    super(props)
    this.state = {
      tipps: [
        { text: i18n.t('fieldMask.tipps.tipp01'), onlyMobile: true },
        { text: i18n.t('fieldMask.tipps.tipp02') },
        { text: i18n.t('fieldMask.tipps.tipp03') },
        { text: i18n.t('fieldMask.tipps.tipp04') },
        { text: i18n.t('fieldMask.tipps.tipp05') }
      ]
    }
  }

  scrollLeft = () => {
    if (this.scrollWrapperRef.current) {
      this.scrollWrapperRef.current.scrollBy({ left: -314, behavior: 'smooth' })
    }
  }

  scrollRight = () => {
    if (this.scrollWrapperRef.current) {
      this.scrollWrapperRef.current.scrollBy({ left: 314, behavior: 'smooth' })
    }
  }

  render() {
    return (
      <div className='fieldmaskContainer-tipps'>
        <div className='tipps-header'>
          <div className='header-title'>Tipps</div>
          <div className='header-actions'>
            <StaigeButton icon={iconChevronLeft} size='small' style='ghost' onClick={this.scrollLeft} />
            <StaigeButton icon={iconChevronRight} size='small' style='ghost' onClick={this.scrollRight} />
          </div>
        </div>
        <div className='tipps-scroll' ref={this.scrollWrapperRef}>
          <div className='scroll-wrapper'>
            {this.state.tipps.map((tipp, index) => {
              if (tipp.onlyMobile && !isMobile) {
                return null
              }

              return (
                <div className='wrapper-item' key={index}>
                  <div className='item-text'>{tipp.text}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}
