import * as Helper from '../helper/GlobalHelper'

// dev
// const url = `${'https://europe-west1-aisw-ww-dev.cloudfunctions.net'}/api_backstaige_v2`
// live
const url = `${process.env.REACT_APP_API_URL}/api_backstaige_v2`

type reasonMail = {
  changeAbo: boolean
  notWorkingAbo: boolean
  onlyForOneEvent: boolean
  tooExpensive: boolean
  iDontNeedMinutes: boolean
  serviceBad: boolean
  tooManyTechProblems: boolean
  otherReason: boolean
  textarea: string
}

type Connetion = {
  userToken: string
  serverUrl: string
}

export enum ErrorMessages {
  UnableCreateRoom = 'Unable to create WebRTC room',
  RecorderIsRunning = 'Recorder is already running'
}

type ErrorRes = {
  message: ErrorMessages.UnableCreateRoom | ErrorMessages.RecorderIsRunning
  error: string
  statusCode: number
}

// Contingent
export const postContingentCreate = async (contractId: string, beta?: boolean) => {
  const backstaigeUrl = beta ? 'https://beta.backstaige.com' : 'https://backstaige.com'
  const successUrl = `${backstaigeUrl}/payment/success/{CHECKOUT_SESSION_ID}`
  const cancelUrl = `${backstaigeUrl}/payment/cancel`

  return await Helper.apiPost<string>(`${url}/contingent/subscription/create`, {
    contractId,
    successUrl,
    cancelUrl
  })
}

export const postContingentFinish = async (contractId: string, sessionId: string) => {
  return await Helper.apiPost<{ contractId: string; sessionId: string }>(
    `${url}/contingent/subscription/finish`,
    {
      contractId,
      sessionId
    }
  )
}

export const postContingentCancel = async (contractId: string) => {
  return await Helper.apiPost<{ contractId: string; sessionId: string }>(
    `${url}/contingent/subscription/cancel`,
    {
      contractId
    }
  )
}

// mail sending
export const postContingentCancelReason = async (contractId: string, json: reasonMail) => {
  const newMail = { ...json, contractId: contractId }
  return await Helper.apiPost<{ contractId: string; sessionId: string }>(
    `${url}/contingent/subscription/cancel/reason`,
    JSON.stringify(newMail)
  )
}

// webRTC
export const getWebRTCConnection = async (cameraId: string) => {
  return await Helper.apiGet<Connetion | ErrorRes>(`${url}/webrtc/connection/${cameraId}`)
}

export const getCameraSnapshot = async (cameraId: string) => {
  const header = await Helper.getAuthorization()
  header['Content-Type'] = 'image/jpeg'
  return fetch(`${url}/webrtc/snapshot/${cameraId}`, { headers: header })
    .then((response) => response.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob)
      return url
    })
    .catch((error) => console.error('Error:', error))
}
