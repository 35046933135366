export function selectTeamAgeGroups(): Array<{ text: string; value: string }> {
  return [
    {
      text: '',
      value: ''
    },
    {
      text: 'A-Junioren',
      value: 'A-Junioren'
    },
    {
      text: 'A-Juniorinnen',
      value: 'A-Juniorinnen'
    },
    {
      text: 'B-Junioren',
      value: 'B-Junioren'
    },
    {
      text: 'B-Juniorinnen',
      value: 'B-Juniorinnen'
    },
    {
      text: 'C-Junioren',
      value: 'C-Junioren'
    },
    {
      text: 'C-Juniorinnen',
      value: 'C-Juniorinnen'
    },
    {
      text: 'D-Junioren',
      value: 'D-Junioren'
    },
    {
      text: 'D-Juniorinnen',
      value: 'D-Juniorinnen'
    },
    {
      text: 'E-Junioren',
      value: 'E-Junioren'
    },
    {
      text: 'E-Juniorinnen',
      value: 'E-Juniorinnen'
    },
    {
      text: 'F-Junioren',
      value: 'F-Junioren'
    },
    {
      text: 'G-Junioren',
      value: 'G-Junioren'
    },
    {
      text: 'Bambini',
      value: 'Bambini'
    },
    {
      text: 'Frauen',
      value: 'Frauen'
    },
    {
      text: 'Frauen Ü30',
      value: 'Frauen Ü30'
    },
    {
      text: 'Herren',
      value: 'Herren'
    },
    {
      text: 'Herren-Reserve',
      value: 'Herren-Reserve'
    },
    {
      text: 'Alte Herren',
      value: 'Alte Herren'
    },
    {
      text: 'Alte Herren Ü32',
      value: 'Alte Herren Ü32'
    },
    {
      text: 'Alte Herren Ü35',
      value: 'Alte Herren Ü35'
    },
    {
      text: 'Altherren',
      value: 'Altherren'
    },
    {
      text: 'Alt-Herren Ü32',
      value: 'Alt-Herren Ü32'
    },
    {
      text: 'Alte-Herren Ü40',
      value: 'Alte-Herren Ü40'
    },
    {
      text: 'Alte-Herren Ü50',
      value: 'Alte-Herren Ü50'
    },
    {
      text: 'Alte-Herren Ü60',
      value: 'Alte-Herren Ü60'
    },
    {
      text: 'Altsenioren Ü40',
      value: 'Altsenioren Ü40'
    },
    {
      text: 'Altsenioren Ü50',
      value: 'Altsenioren Ü50'
    },
    {
      text: 'Altsenioren Ü60',
      value: 'Altsenioren Ü60'
    },
    {
      text: 'Oldies Ü40',
      value: 'Oldies Ü40'
    },
    {
      text: 'Oldies Ü50',
      value: 'Oldies Ü50'
    },
    {
      text: 'Oldies Ü60',
      value: 'Oldies Ü60'
    },
    {
      text: 'Senioren Ü32',
      value: 'Senioren Ü32'
    },
    {
      text: 'Senioren Ü35',
      value: 'Senioren Ü35'
    },
    {
      text: 'Ü32 Senioren',
      value: 'Ü32 Senioren'
    },
    {
      text: 'Ü50 Senioren',
      value: 'Ü50 Senioren'
    },
    {
      text: 'Freizeitsport',
      value: 'Freizeitsport'
    },
    {
      text: 'Hobby-Freizeitsport',
      value: 'Hobby-Freizeitsport'
    },
    {
      text: 'U23',
      value: 'U23'
    },
    {
      text: 'U22',
      value: 'U22'
    },
    {
      text: 'U21',
      value: 'U21'
    },
    {
      text: 'U20',
      value: 'U20'
    },
    {
      text: 'U19',
      value: 'U19'
    },
    {
      text: 'U18',
      value: 'U18'
    },
    {
      text: 'U17',
      value: 'U17'
    },
    {
      text: 'U16',
      value: 'U16'
    },
    {
      text: 'U15',
      value: 'U15'
    },
    {
      text: 'U14',
      value: 'U14'
    },
    {
      text: 'U13',
      value: 'U13'
    },
    {
      text: 'U12',
      value: 'U12'
    },
    {
      text: 'U11',
      value: 'U11'
    },
    {
      text: 'U10',
      value: 'U10'
    },
    {
      text: 'U9',
      value: 'U9'
    },
    {
      text: 'U8',
      value: 'U8'
    },
    {
      text: 'U7',
      value: 'U7'
    },
    {
      text: 'All Ages (Rest)',
      value: 'All Ages (Rest)'
    }
  ]
}
