import * as Discovery from '@soccerwatch/discovery'
import * as Helper from '../helper/GlobalHelper'
import { CameraClaim, Contract, License } from '@soccerwatch/common'
import { UserData } from '../services/contractContainer'
import axios from 'axios'

export const getUserById = async (id: string) => {
  return await Helper.apiGet<UserData>(Discovery.API_USER + `/user/${id}`)
}

export const getUserByMail = async (mail: string) => {
  return await Helper.apiGet<{ uid: string; userExists: boolean; displayName: string }>(
    Discovery?.API_USER + `/intern/getByMail/${mail}`
  )
}

export const getContractsMe = async () => {
  return await Helper.apiGet<Contract[]>(Discovery.API_USER + '/Contracts/me/')
}

export const getContractById = async (id: string, callAs: string) => {
  return await Helper.apiGet<Contract>(`${Discovery.API_USER}/Contract/${id}${callAs}`)
}

export const postContract = async (body: { name: string; clubIds: string[]; RowKey: string }) => {
  return await Helper.apiPost(`${Discovery.API_USER}/Contract`, body)
}

export const postAddUserToContract = async (body: any, callAs: string) => {
  return await Helper.apiPost<License>(Discovery?.API_USER + `/addUserToContract${callAs}`, body)
}

export const getUserLicences = async () => {
  return await Helper.apiList<License>(`${Discovery?.API_USER}/licenses`)
}

export const getCameraClaimToken = async (token: string) => {
  return await Helper.apiGet<{ data: Array<CameraClaim> }>(`${Discovery.API_USER}/camera/claim/${token}`)
}

export const getLicensesOfContract = async (id: string) => {
  return await Helper.apiList<License>(Discovery?.API_USER + `/licensesOfContract/${id}`)
}

export const deleteLicenseByUserId = async (userId: string, subId: string, callAs: string) => {
  return await Helper.apiDelete<License>(Discovery?.API_USER + `/license/delete/${subId}/${userId}${callAs}`)
}

export const getTokenMe = async (token: string) => {
  const headers = await Helper.getAuthorizationCustomToken(token)
  return await axios.get(Discovery?.API_USER + `/user/token/me/`, { headers })
}
