import i18n from 'i18next'

import { InstreamAd, InstreamTypes, LottyAd } from '@soccerwatch/common'

export type ImageDimensionRestrictions = {
  width: number
  height: number
}

export const resizeImageToCoverDimensions = (
  file: File,
  fileContent: string,
  restrictions: ImageDimensionRestrictions
): Promise<File> => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas')
    canvas.style.display = 'none'
    canvas.width = restrictions.width
    canvas.height = restrictions.height
    const ctx = canvas.getContext('2d')

    const img = new Image()
    img.src = fileContent
    img.onload = () => {
      if (ctx) {
        // Determine scale factor that will cover the entire canvas (i.e. target size)
        const scaleFactorX = restrictions.width / img.width
        const scaleFactorY = restrictions.height / img.height
        const finalScaleFactor = Math.max(scaleFactorX, scaleFactorY)
        const scaledWidth = Math.round(img.width * finalScaleFactor)
        const scaledHeight = Math.round(img.height * finalScaleFactor)

        ctx.drawImage(
          img,
          (scaledWidth - restrictions.width) / -2,
          (scaledHeight - restrictions.height) / -2,
          scaledWidth,
          scaledHeight
        )

        canvas.toBlob((blob) => {
          canvas.remove()
          if (blob) {
            resolve(
              new File([blob], `${file.name}`, {
                type: file.type
              })
            )
          } else {
            reject()
          }
        })
      } else {
        reject(new Error(`Canvas for image conversion couldn't be found`))
      }
    }
  })
}

export const validateImageMetaData = (
  restrictions: { dimensions: ImageDimensionRestrictions },
  file?: File,
  fileContent?: string
) =>
  new Promise<string | undefined>((resolve) => {
    if (fileContent) {
      const img = new Image()
      img.src = fileContent
      img.onload = () => {
        const isValid =
          img.width === restrictions.dimensions.width && img.height === restrictions.dimensions.height
        resolve(
          isValid
            ? undefined
            : i18n.t('editAdvertisement.labels.imageDimensionErrorMessage', restrictions.dimensions)
        )
      }
    } else {
      resolve(undefined)
    }
  })

export const createImageSizeValidator =
  (imageDimensionRestrictions: ImageDimensionRestrictions) => (file?: File, fileContent?: string) =>
    validateImageMetaData({ dimensions: imageDimensionRestrictions }, file, fileContent)

/**
 * Checks video dimensions and duration of a given video file.
 * Returns i18n of appropriate error message or undefined
 * @param restrictions - Duration in seconds, dimensions in px
 * @param file
 * @param fileContent
 */
export const validateVideoMetaData = (
  restrictions: {
    maxDuration?: number
    minDuration?: number
    frameDimensionRestrictions?: ImageDimensionRestrictions
  },
  file?: File,
  fileContent?: string
) => {
  return new Promise<string | undefined>((resolve) => {
    if (fileContent) {
      const videoElement = document.createElement('video')
      videoElement.style.display = 'none'
      videoElement.src = fileContent
      document.body.append(videoElement)

      videoElement.addEventListener(
        'error',
        () => {
          resolve(i18n.t('editAdvertisement.errorMessages.invalidVideoFile'))
        },
        { once: true }
      )

      videoElement.addEventListener(
        'loadedmetadata',
        () => {
          const dimensionsAreValid =
            restrictions.frameDimensionRestrictions === undefined ||
            (videoElement.videoHeight === restrictions.frameDimensionRestrictions.height &&
              videoElement.videoWidth === restrictions.frameDimensionRestrictions.width)
          videoElement.remove()

          const durationIsValid =
            (restrictions.minDuration === undefined || restrictions.minDuration <= videoElement.duration) &&
            (restrictions.maxDuration === undefined || videoElement.duration <= restrictions.maxDuration)

          const isValid = dimensionsAreValid && durationIsValid

          if (isValid) {
            resolve(undefined)
          } else if (!dimensionsAreValid) {
            resolve(
              i18n.t(
                'editAdvertisement.errorMessages.wrongVideoDimensions',
                restrictions.frameDimensionRestrictions
              )
            )
          } else {
            resolve(
              i18n.t('editAdvertisement.errorMessages.wrongVideoDuration', {
                min: restrictions.minDuration ?? 0,
                max: restrictions.maxDuration ?? Infinity
              })
            )
          }
        },
        { once: true }
      )
    } else {
      resolve(undefined)
    }
  })
}

const bytesInKibiByte = 1 << 10
export const validateFileSizeLowerKibiByte = (numberOfKibiBytes: number, file?: File) => {
  if (file) {
    const isValid = file.size <= numberOfKibiBytes * bytesInKibiByte
    return isValid
      ? undefined
      : i18n.t('editAdvertisement.errorMessages.fileTooLarge', {
          maxFileSize: `${numberOfKibiBytes} KiB`
        })
  } else {
    return undefined
  }
}
export const validateFileSizeLowerMibiByte = (numberOfMibiBytes: number, file?: File) => {
  const isValid = validateFileSizeLowerKibiByte(numberOfMibiBytes << 10, file) === undefined
  return isValid
    ? undefined
    : i18n.t('editAdvertisement.errorMessages.fileTooLarge', {
        maxFileSize: `${numberOfMibiBytes} MiB`
      })
}

export const validateTextLength = (value: string | undefined, maxLength: number, valueName: string) => {
  if (!value || value.length > maxLength) {
    return i18n.t('editAdvertisement.errorMessages.textTooLong', {
      textName: valueName,
      maxLength: maxLength
    })
  } else {
    return undefined
  }
}

export const validateURL = (value?: string) => {
  let url: URL
  try {
    url = new URL(value ?? 'invalidURL')
  } catch (e) {
    return false
  }

  // Disallow links such as javascript:void(0)
  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const isValidAdMaterial = (ad: InstreamAd | LottyAd): ad is InstreamAd => {
  const destinationURLIsValid = (ad.link ?? '') === '' || validateURL(ad.link)

  const title = ad.name.length > 0

  let otherPropertiesAreCorrect: boolean

  if (ad.instreamType === InstreamTypes.IMAGE || ad.instreamType === InstreamTypes.STATIC) {
    otherPropertiesAreCorrect = validateURL(ad.link) && !!ad.mediaInstream && ad.mediaInstream !== ''
  } else if (ad.instreamType === InstreamTypes.LOTTY) {
    const lottyAd = ad as LottyAd
    otherPropertiesAreCorrect =
      (lottyAd.mediaLottyInstream === undefined || validateURL(lottyAd.mediaLottyInstream)) &&
      validateURL(lottyAd.mediaLottyInstreamSide)
  } else {
    otherPropertiesAreCorrect = false
  }
  return Boolean(destinationURLIsValid && title && otherPropertiesAreCorrect)
}
