import { Container } from 'unstated-typescript'
import Discovery, { assertContainerHasInitializedDiscovery } from './discovery'
import CameraContainer from './cameraContainer'
import ClubsContainer from './clubContainer'
import DiscoveryType from '@soccerwatch/discovery'
import moment from 'moment'
import UserLogin from './userContainer'
import mobiscroll from '@mobiscroll/react'
import i18n from '../languages/i18n'

import { PartialExceptProperties, Role, VideoData } from '@soccerwatch/common'
import { ContractContainer } from './contractContainer'
import { searchAndSortGetCallAs, searchAndSortGetRoles } from './serviceHelper'
import {
  getVideoListBetweenDate,
  getVideoListByCameraBetweenDate,
  getVideoListByContract,
  getVideoListForClubBetweenDate,
  getVideoMetaById,
  postVideoMetaById
} from '../api/api-video'

type VideoState = {
  loadingData: boolean
  initialDataLoaded: boolean
  videos: Record<string, VideoData[]>
  loadedTimestamps: Record<string, string[]>
  startTime: string
  endTime: string
  openRequests: number
  discovery?: typeof DiscoveryType
  loadingAllContractVideos: boolean
}

const unique = (value: VideoData, index: number, self: VideoData[]) =>
  index === self.findIndex((t) => t.RowKey === value.RowKey)

export class VideoContainer extends Container<VideoState> {
  discovery?: typeof DiscoveryType
  contractContainer?: ContractContainer
  constructor() {
    super()

    this.state = {
      loadingData: false,
      initialDataLoaded: false,
      videos: {},
      loadedTimestamps: {},
      openRequests: 0,
      startTime: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      endTime: moment().add(1, 'days').format('YYYY-MM-DD'),
      discovery: undefined,
      loadingAllContractVideos: false
    }

    Discovery.then((d) => {
      this.setState({ discovery: d })
      if (UserLogin.state.loggedIn) {
      }
    })
  }

  initialize(contractContainer: ContractContainer) {
    this.contractContainer = contractContainer
    this.contractContainer.subscribeToContractChanged(
      'VideoContractContainer',
      this.onContractChanged,
      this.onBeforeContractChanged
    )
    if (contractContainer.getHighestRelevantRoleForCurrentContract() === Role.adManager) {
      return
    }
    const { videos, loadedTimestamps } = this.state
    contractContainer.state.contracts.forEach((contract) => {
      if (!videos[contract.RowKey]) {
        videos[contract.RowKey] = []
      }
      if (!loadedTimestamps[contract.RowKey]) {
        loadedTimestamps[contract.RowKey] = []
      }
    })
    this.setState({ videos, loadedTimestamps })
  }

  onBeforeContractChanged = (nextIndex: number, activeContract: () => number) => {
    return new Promise<void>((resolve) => {
      if (nextIndex !== activeContract()) return resolve()
      this.setState({ loadingData: true }, async () => {
        if (nextIndex !== activeContract()) return resolve()
        await this.setVideoListByTimestamp(undefined, true, nextIndex)
        if (nextIndex !== activeContract()) return resolve()
        resolve()
      })
    })
  }

  onContractChanged = () => {}

  reloadVideoFullList = () => {
    this.setVideoListByTimestamp(undefined, true, undefined, true)
  }

  setVideoById = async (videoId: number | string, reloadClubs?: boolean) => {
    assertContainerHasInitializedDiscovery(this)
    if (!this.contractContainer) {
      return console.error(
        'Contract Container not Initialized. Could not get Videos of Camera! This should not happen! BAD!'
      )
    }
    const currentContract = this.contractContainer?.getContract()
    if (!currentContract) {
      console.error(
        '<VideoContainer> Method Called before ContractContainer initialized. This should not happen.'
      )
      return
    }
    const callAs = searchAndSortGetCallAs([
      Role.admin,
      this.contractContainer.getHighestRelevantRoleForCurrentContract()
    ])
    let video = await getVideoMetaById(videoId, callAs)
    const videos = JSON.parse(JSON.stringify(this.state.videos)) as Record<string, VideoData[]>
    const currentVideos = videos[currentContract.RowKey].filter(
      (meta) => String(meta.RowKey) !== String(videoId)
    )

    // set address
    const camera = CameraContainer.state.cameras[video.swcsID]
    if (camera) {
      video.address = camera.address
    } else {
      // TODO: Check if this case may occur somehow and how to handle it
      console.error('Could not find Cam', video.swcsID, 'in CameraContainer. This should not Happen!')
    }
    // push video to videolist
    currentVideos.push(video)
    videos[currentContract.RowKey] = currentVideos
    await this.setState({ videos: videos })
    if (reloadClubs) {
      await ClubsContainer.setClubHomeGuestById(video.clubAId, video.clubBId)
    }
  }

  updateVideo = async (
    updatedVideo: PartialExceptProperties<VideoData, 'RowKey' | 'expectedStartTime' | 'durationMin'>,
    incompleteData?: boolean
  ) => {
    assertContainerHasInitializedDiscovery(this)

    try {
      await postVideoMetaById(updatedVideo, updatedVideo.RowKey)
    } catch (error: any) {
      if (error.response?.data?.status === 'out of streaming minutes') {
        const msg = i18n.t('recordingPlan.toastText.outOfStreamingMinutes')
        await mobiscroll.toast({ color: 'warning', message: msg })
      }
    }

    const currentContract = this.contractContainer?.getContract()
    if (!currentContract) {
      console.error(
        '<VideoContainer> Method Called before ContractContainer initialized. This should not happen.'
      )
      return
    }
    const videos = JSON.parse(JSON.stringify(this.state.videos)) as Record<string, VideoData[]>
    const index = videos[currentContract.RowKey].findIndex((item) => {
      return String(item.RowKey) === String(updatedVideo.RowKey)
    })
    if (!index && index !== 0) {
      return console.error('<VideoContainer> Could not find Updated Video. This should not happen.')
    }
    if (incompleteData) {
      videos[currentContract.RowKey][index].expectedStartTime = updatedVideo.expectedStartTime
      videos[currentContract.RowKey][index].durationMin = updatedVideo.durationMin
    } else {
      videos[currentContract.RowKey][index] = {
        ...videos[currentContract.RowKey][index],
        ...updatedVideo
      } as VideoData
    }

    await this.setState({ videos })
    return videos[currentContract.RowKey][index]
  }

  updateVideoStopRecording = async (updatedVideo: { RowKey: string; stopRequested: boolean }) => {
    assertContainerHasInitializedDiscovery(this)

    await postVideoMetaById(updatedVideo, updatedVideo.RowKey, '')

    const currentContract = this.contractContainer?.getContract()
    if (!currentContract) {
      console.error(
        '<VideoContainer> Method Called before ContractContainer initialized. This should not happen.'
      )
      return
    }
    const videos = JSON.parse(JSON.stringify(this.state.videos)) as Record<string, VideoData[]>
    const index = videos[currentContract.RowKey].findIndex((item) => {
      String(item.RowKey) === String(updatedVideo.RowKey)
    })
    if (!index && index !== 0) {
      return console.error('<VideoContainer> Could not find Updated Video. This should not happen.')
    }

    await this.setState({ videos })
  }

  updateVideoGeneric = async (updatedVideo: { RowKey: string }) => {
    assertContainerHasInitializedDiscovery(this)

    await postVideoMetaById(updatedVideo, updatedVideo.RowKey, '')

    const currentContract = this.contractContainer?.getContract()
    if (!currentContract) {
      console.error(
        '<VideoContainer> Method Called before ContractContainer initialized. This should not happen.'
      )
      return
    }
    const videos = JSON.parse(JSON.stringify(this.state.videos)) as Record<string, VideoData[]>
    const index = videos[currentContract.RowKey].findIndex((item) => {
      String(item.RowKey) === String(updatedVideo.RowKey)
    })
    if (!index && index !== 0) {
      return console.error('<VideoContainer> Could not find Updated Video. This should not happen.')
    }

    await this.setState({ videos })
  }

  addCameraDataToVideo = (video: VideoData) => {
    const camera = CameraContainer.state.cameras[video.swcsID]
    if (camera) {
      video.address = camera.address
    } else {
      // TODO: Check if this case may occur somehow and how to handle it
      console.error('Could not find Cam', video.swcsID, 'in CameraContainer. This should not Happen!')
    }
    return video
  }

  getVideosByCamera = async (
    cameraId: string,
    startTime: string,
    endTime: string,
    contractIndex?: number
  ) => {
    assertContainerHasInitializedDiscovery(this)
    if (!this.contractContainer) {
      return console.error(
        'Contract Container not Initialized. Could not get Videos of Camera! This should not happen! BAD!'
      )
    }
    const callAs = searchAndSortGetRoles([
      Role.admin,
      this.contractContainer.getHighestRelevantRoleForContract(contractIndex)
    ])

    // if the user has only adManager role, it should be aborted because the authorization is missing
    if (callAs === Role.adManager || callAs === Role.trainer) {
      return
    }

    const res = await getVideoListByCameraBetweenDate(cameraId, startTime, endTime, callAs)
    return res
  }

  getAllVideos = async (startTime: string, endTime: string, contractIndex?: number) => {
    assertContainerHasInitializedDiscovery(this)
    if (!this.contractContainer) {
      return console.error(
        'Contract Container not Initialized. Could not get Videos of Camera! This should not happen! BAD!'
      )
    }
    const callAs = searchAndSortGetRoles([
      Role.admin,
      this.contractContainer.getHighestRelevantRoleForContract(contractIndex)
    ])
    const res = await getVideoListBetweenDate(startTime, endTime, callAs)
    return res
  }

  getStartAndEndTime = (currentTime: string, month?: boolean) => {
    if (month) {
      return {
        startTime: moment(currentTime).startOf('month').format('YYYY-MM-DD'),
        endTime: moment(currentTime).endOf('month').format('YYYY-MM-DD')
      }
    }
    return {
      startTime: moment(currentTime).subtract(1, 'days').format('YYYY-MM-DD'),
      endTime: moment(currentTime).add(1, 'days').format('YYYY-MM-DD')
    }
  }

  getVideosByCameraList = async (
    cameraList: string[],
    startTime: string,
    endTime: string,
    contractIndex?: number
  ) => {
    let videos: VideoData[] = []
    if (cameraList.length < 20) {
      for (let i in cameraList) {
        const cameraId = cameraList[i]
        let cameraVids = await this.getVideosByCamera(cameraId, startTime, endTime, contractIndex)
        if (!cameraVids) {
          continue
        }
        videos = videos.concat(cameraVids)
      }
    } else {
      const allVids = await this.getAllVideos(startTime, endTime, contractIndex)
      if (!allVids) {
        return []
      }
      const cameraVids = allVids.filter((video) => cameraList.includes(video.swcsID))
      videos = videos.concat(cameraVids)
    }
    return videos
  }

  getVideosByClub = async (
    clubId: string,
    startTime: string,
    endTime: string,
    contractIndex?: number,
    isAway = false
  ) => {
    assertContainerHasInitializedDiscovery(this)
    if (!this.contractContainer) {
      return console.error(
        'Contract Container not Initialized. Could not get Videos of Camera! This should not happen! BAD!'
      )
    }
    const callAs = searchAndSortGetRoles([
      Role.admin,
      this.contractContainer.getHighestRelevantRoleForContract(contractIndex)
    ])
    const homeOrAway = isAway ? 'Away' : 'Home'
    const res = await getVideoListForClubBetweenDate(homeOrAway, clubId, startTime, endTime, callAs)
    return res
  }

  getVideosByClubList = async (
    clubList: string[],
    startTime: string,
    endTime: string,
    contractIndex?: number
  ) => {
    let videos: VideoData[] = []
    if (clubList.length < 10) {
      for (let i in clubList) {
        const clubId = clubList[i]
        let clubHomeVidsProm = this.getVideosByClub(clubId, startTime, endTime, contractIndex, false)
        let clubAwayVidsProm = this.getVideosByClub(clubId, startTime, endTime, contractIndex, true)
        const clubHomeVids = await clubHomeVidsProm
        const clubAwayVids = await clubAwayVidsProm
        if (!clubHomeVids && !clubAwayVids) {
          continue
        }
        videos = videos.concat(clubHomeVids ?? [])
        videos = videos.concat(clubAwayVids ?? [])
      }
    } else {
      const allVids = await this.getAllVideos(startTime, endTime, contractIndex)
      if (!allVids) {
        return []
      }
      const clubVids = allVids.filter(
        (video) => clubList.includes(video.clubAId) || clubList.includes(video.clubBId)
      )
      videos = videos.concat(clubVids)
    }
    return videos
  }

  getVideosForCurrentContract = () => {
    return this.getVideosForContract()
  }

  getVideosForContract = (index?: number) => {
    if (!this.contractContainer) {
      if (this.state.initialDataLoaded) {
        console.error('<VideoContainer>: Contract Container not Initialized. This should not happen!')
      }
      return []
    }
    const contract = this.contractContainer.getContract(index)
    if (!contract) {
      if (!this.contractContainer.state.loadingData) {
        console.error('<VideoContainer> No Active Contract Set. This should not happen!')
      }
      return []
    }
    if (!this.state.videos[contract.RowKey] && !this.state.loadingData) {
      console.error(
        '<VideoContainer> Videos of Contract',
        contract.RowKey,
        'not in State. This should not Happen!'
      )
      return []
    }
    return this.state.videos[contract.RowKey]
  }

  loadInitialData = async () => {
    await this.setState({ initialDataLoaded: false })
    await this.setVideoListByTimestamp(undefined, true)
    await this.setState({ initialDataLoaded: true })
  }

  getAllVideosOfContract = async (contractId: string) => {
    assertContainerHasInitializedDiscovery(this)
    await this.setState({ loadingAllContractVideos: true })
    const videos = await getVideoListByContract(contractId)
    return videos
  }

  setVideoListByTimestamp = async (
    currentTime?: string,
    month?: boolean,
    contractIndex?: number,
    force = false
  ) => {
    if (!this.contractContainer) {
      console.error('Contract Container not Initialized. This should not happen')
      return
    }
    if (!currentTime && !moment(currentTime).isBetween(this.state.startTime, this.state.endTime) && !force) {
      return
    }
    currentTime = moment(currentTime).format('YYYY-MM-DD') || moment().format('YYYY-MM-DD')
    const currentContract = this.contractContainer?.getContract(contractIndex)
    const loadedTimestamps = JSON.parse(JSON.stringify(this.state.loadedTimestamps)) as Record<
      string,
      string[]
    >
    if (currentContract && loadedTimestamps[currentContract.RowKey]?.includes(currentTime) && !force) {
      await this.setState({ loadingData: false })
      return
    }

    await this.setState({ loadingData: true, openRequests: this.state.openRequests + 1 })

    const { startTime, endTime } = this.getStartAndEndTime(currentTime, month)
    // If user has Contracts, fetch Videos for Contracts
    let videos = JSON.parse(JSON.stringify(this.state.videos)) as Record<string, VideoData[]>
    if (currentContract) {
      if (!videos[currentContract.RowKey]) {
        videos[currentContract.RowKey] = []
      }
      if (!loadedTimestamps[currentContract.RowKey]) {
        loadedTimestamps[currentContract.RowKey] = []
      }

      const fetchedVids =
        this.contractContainer.getHighestRelevantRoleForCurrentContract() !== Role.clubTagger
          ? await this.getVideosByCameraList(currentContract.cameraIds, startTime, endTime, contractIndex)
          : await this.getVideosByClubList(currentContract.clubIds, startTime, endTime, contractIndex)
      videos[currentContract.RowKey] = videos[currentContract.RowKey].concat(fetchedVids).filter(unique)
      loadedTimestamps[currentContract.RowKey].push(currentTime)
    } else {
      console.warn(
        '<VideoContainer> ContractContainer not Initialized or User has no Contracts. This should not happen.'
      )
    }

    const loadingData = this.state.openRequests - 1 !== 0
    await this.setState({ videos, loadedTimestamps, loadingData, openRequests: this.state.openRequests - 1 })
  }
}

const videoContainerContract = new VideoContainer()
export default videoContainerContract
