import React from 'react'
import mobiscroll from '@mobiscroll/react'
import i18n from 'i18next'
import HeaderWrapper from '../../helper/PageWrapper'
import Fuse from 'fuse.js'

import './Support.scss'

type SupportPropTypes = {
  nothing?: string
}

type SearchListItem = {
  headlineText: string
  contentString: string
  content: JSX.Element
}
type SearchList = Array<SearchListItem>

type SupportStateTypes = {
  activeIndex: number | undefined
  activeSecondIndex: number | undefined
  activeFilterIndex: number | undefined
  configForSearch: SearchList
  searchText: string
  filterSearch: SearchList
}

const supportConfig = [
  {
    menuHeadlineText: 'Installation & Einrichtung',
    content: [
      {
        headlineText: 'Wie wird die Kamera Angeschlossen',
        content: (
          <div>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
            labore et dolore magna <br />
            <br />
            aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
            clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor
            sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
            <img src='https://img.pr0gramm.com/2020/06/22/fffbcd282734604e.jpg' />
            ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
            dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
            amet.
          </div>
        )
      },
      {
        headlineText: 'Bla Bla Bla kamera',
        content: <div>Hier könnte ihr text stehen!</div>
      }
    ]
  },
  {
    menuHeadlineText: 'Bestellung Bestellung Bestellung Bestellung',
    content: [
      {
        headlineText: 'Wie wird die Kamera Angeschlossen',
        content: <div>Aufmerksamkeitsdefizit-Hyperaktivitätsstörung</div>
      }
    ]
  }
]

export default class Support extends React.Component<SupportPropTypes, SupportStateTypes> {
  constructor(props: SupportPropTypes) {
    super(props)

    this.state = {
      activeIndex: undefined,
      activeSecondIndex: undefined,
      activeFilterIndex: undefined,
      configForSearch: [],
      searchText: '',
      filterSearch: []
    }
  }

  componentDidMount() {
    const searchList: SearchList = []
    supportConfig.map((menuTap) => {
      menuTap.content.map((item) => {
        let contentParseToString = ''

        if (item.content.props.children instanceof Array) {
          item.content.props.children.map((child: unknown) => {
            if (typeof child === 'string' || child instanceof String) {
              contentParseToString = child + ' ' + contentParseToString
            }
          })
        } else if (
          typeof item.content.props.children === 'string' ||
          item.content.props.children instanceof String
        ) {
          contentParseToString = item.content.props.children + ' ' + contentParseToString
        }

        searchList.push({
          headlineText: item.headlineText,
          contentString: contentParseToString,
          content: item.content
        })
      })
    })

    this.setState({
      configForSearch: searchList
    })
  }

  setSupportSite = (index: number) => {
    this.setState({
      activeIndex: index,
      activeSecondIndex: undefined
    })
  }

  setSupportSecondSite = (index: number) => {
    this.setState({
      activeSecondIndex: index
    })
  }

  setFilterSite = (index: number) => {
    this.setState({
      activeFilterIndex: index
    })
  }

  search(searchText: string) {
    const options = {
      threshold: 0.4,
      keys: ['headlineText', 'contentString']
    }

    const fuse = new Fuse(this.state.configForSearch, options)
    const parseFuse = fuse.search(searchText).map((obj) => {
      return obj.item
    })

    this.setState({
      searchText: searchText,
      filterSearch: parseFuse,
      activeIndex: this.state.searchText !== searchText ? undefined : this.state.activeIndex,
      activeSecondIndex: this.state.searchText !== searchText ? undefined : this.state.activeSecondIndex
    })
  }

  renderMenuHeadlineText = (headline: string, index: number, setIndex: (index: number) => void) => {
    return (
      <div key={index} onClick={() => setIndex(index)} className='support-menuItem'>
        <div>{headline}</div>
        <div className='mbsc-ic mbsc-ic-fa-angle-right arrowSize' />
      </div>
    )
  }

  renderActiveContent = (obj: SearchListItem, removeIndex: () => void) => {
    return (
      <div>
        <div onClick={() => removeIndex} className='support-menuItem support-headline'>
          <div className='mbsc-ic mbsc-ic-fa-angle-left arrowLeft arrowSize' />
          <div className='menuItem-headline'>{obj.headlineText}</div>
        </div>

        <div className='support-content'>{obj.content}</div>
      </div>
    )
  }

  renderSupportContent = () => {
    let content
    if (this.state.activeIndex === undefined) {
      content = supportConfig.map((config, index) => {
        return this.renderMenuHeadlineText(config.menuHeadlineText, index, this.setSupportSite)
      })
    } else {
      if (this.state.activeSecondIndex === undefined) {
        content = supportConfig.map((config, index) => {
          if (this.state.activeIndex === index) {
            return (
              <div key={index}>
                <div
                  onClick={() => this.setState({ activeIndex: undefined, activeSecondIndex: undefined })}
                  className='support-menuItem support-headline'
                >
                  <div className='mbsc-ic mbsc-ic-fa-angle-left arrowLeft arrowSize' />
                  <div className='menuItem-headline'>{config.menuHeadlineText}</div>
                </div>

                <div className='menuItem-bottom'>
                  {config.content.map((config, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => this.setSupportSecondSite(index)}
                        className='support-menuItem'
                      >
                        <div>{config.headlineText}</div>
                        <div className='mbsc-ic mbsc-ic-fa-angle-right arrowSize' />
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          }
        })
      } else {
        const contentObj = supportConfig[this.state.activeIndex].content[this.state.activeSecondIndex]
        content = (
          <div>
            <div
              onClick={() => this.setState({ activeSecondIndex: undefined })}
              className='support-menuItem support-headline'
            >
              <div className='mbsc-ic mbsc-ic-fa-angle-left arrowLeft arrowSize' />
              <div className='menuItem-headline'>{contentObj.headlineText}</div>
            </div>

            <div className='support-content'>{contentObj.content}</div>
          </div>
        )
      }
    }

    // for search rendering
    if (this.state.searchText.length > 0) {
      if (this.state.filterSearch.length === 0) {
        content = (
          <div style={{ textAlign: 'center', marginTop: '40px' }}>{i18n.t('support.noResultText')}</div>
        )
      } else {
        content = this.state.filterSearch.map((obj: SearchListItem, index: number) => {
          if (this.state.activeFilterIndex === undefined) {
            return this.renderMenuHeadlineText(obj.headlineText, index, this.setFilterSite)
          } else if (this.state.activeFilterIndex === index) {
            return (
              <div key={index}>
                <div
                  onClick={() => this.setState({ activeFilterIndex: undefined })}
                  className='support-menuItem support-headline'
                >
                  <div className='mbsc-ic mbsc-ic-fa-angle-left arrowLeft arrowSize' />
                  <div className='menuItem-headline'>{obj.headlineText}</div>
                </div>

                <div className='support-content'>{obj.content}</div>
              </div>
            )
          }
        })
      }
    }

    return (
      <div>
        {this.state.activeIndex === undefined && this.state.activeFilterIndex === undefined ? (
          <div style={{ margin: '0 -1em' }}>
            <mobiscroll.Input
              value={this.state.searchText}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                this.search(event.currentTarget.value)
              }
              placeholder={i18n.t('support.searchText')}
            />
          </div>
        ) : null}
        {content}
      </div>
    )
  }

  render() {
    return (
      <HeaderWrapper headerTitle={'Support'}>
        <h2 style={{ marginTop: 10 }}>{i18n.t('support.headText')}</h2>
        <div>{this.renderSupportContent()}</div>
      </HeaderWrapper>
    )
  }
}
