import { Competition } from '@soccerwatch/common'
import * as Discovery from '@soccerwatch/discovery'
import * as Helper from '../helper/GlobalHelper'

export const getListCompetitionsByTeam = async (teamId: string, retry = 5): Promise<Competition[]> => {
  try {
    return await Helper.apiList<Competition>(`${Discovery.API_COMPETITION}/competitions/by/team/${teamId}`)
  } catch (error) {
    if (retry > 0) {
      await new Promise((resolve) => {
        setTimeout(resolve, 50 + Math.random() * 1000)
      })
      return getListCompetitionsByTeam(teamId, retry - 1)
    } else {
      throw error
    }
  }
}
