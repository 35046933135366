import React from 'react'
import StaigeButton from './Button'
import i18n from '../../languages/i18n'

import './Modal.scss'

type staigeModalPropTypes = {
  title?: string
  text?: string
  active?: boolean
  onConfirm?: (value?: string) => void
  confirmText?: string
  disableCancleBtn?: boolean
  closeOutsideClick?: boolean
  children?: any
}

type staigeModalStateTypes = {
  open: boolean
  confirmOption?: string
}

export default class StaigeModal extends React.Component<staigeModalPropTypes, staigeModalStateTypes> {
  constructor(props: staigeModalPropTypes) {
    super(props)

    this.state = {
      open: false,
      confirmOption: undefined
    }
  }

  private wrapperRef = React.createRef<any>()

  componentDidMount() {
    if (this.props.closeOutsideClick) {
      document.addEventListener('mousedown', this.handleClickOutside)
    }
  }

  componentWillUnmount() {
    if (this.props.closeOutsideClick) {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }
  }

  handleClickOutside = (event: MouseEvent) => {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target as Node)) {
      this.handleCloseModal()
    }
  }

  handleOpenModal = (value?: string) => {
    this.setState({
      open: true,
      confirmOption: value
    })
  }

  handleCloseModal = () => {
    this.setState({
      open: false,
      confirmOption: undefined
    })
  }

  handleConfirm = async () => {
    if (this.props.onConfirm) {
      if (this.state.confirmOption) {
        this.props.onConfirm(this.state.confirmOption)
      } else {
        this.props.onConfirm()
      }
    }

    this.handleCloseModal()
  }

  render() {
    const { title, text, confirmText, disableCancleBtn, children } = this.props
    const { open } = this.state

    if (!open) return null

    return (
      <div className={`staige-modal`}>
        <div className='modal-container' ref={this.wrapperRef}>
          <div className='container-inner'>
            <div className='inner-title'>{title ?? ' Title...'}</div>
            {text ? <div className='inner-text'>{text}</div> : null}
            {children ? <div className='inner-text'>{children}</div> : null}
          </div>
          <div className='container-action'>
            {disableCancleBtn ? null : (
              <StaigeButton style='ghost' label={i18n.t('general.cancel')} onClick={this.handleCloseModal} />
            )}
            <StaigeButton label={confirmText ?? i18n.t('general.confirm')} onClick={this.handleConfirm} />
          </div>
        </div>
      </div>
    )
  }
}
