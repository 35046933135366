import React from 'react'
import mobiscroll from '@mobiscroll/react'
import i18n from 'i18next'
import StaigeTag from '../../../components/styles/Tag'
import StaigeCheckbox from '../../../components/styles/Checkbox'
import LoadingSpinner from '../../helper/loadingSpinner/loadingSpinner'

import { FreeTicket } from '@soccerwatch/common'
import { checkLanguages } from '../../helper/checkLanguages/checkLanguages'

import './paywall.scss'

export type PaywallData = {
  price: number
  freeTickets: Array<FreeTicket>
}

type PaywallPropTypes = {
  setPaywall: (price: number, freeTickets: Array<FreeTicket>) => void
  paywall: PaywallData
  disabled: boolean
  paywallActive: boolean
  isNew: boolean
  paywallDataLoaded: boolean
  paywallAGB: boolean
  handlePaywallAGB: (paywallAGB: boolean) => void
}

export default class Paywall extends React.Component<PaywallPropTypes, unknown> {
  constructor(props: PaywallPropTypes) {
    super(props)
  }

  componentDidMount(): void {
    if (this.props.paywallActive && !this.props.isNew) {
      this.setState({
        disabledAgb: true
      })
    }
  }

  setPrice = (value: number) => {
    const { paywall } = this.props
    let newPasses
    // at a price of 1,90€ the paywall will be deactivated - set of 0€
    value = value < 1.9 ? 0 : value

    if (value <= 0) {
      newPasses = this.generateNFreePasses(0)
    }

    this.props.setPaywall(value, newPasses ? newPasses : paywall.freeTickets)
  }

  private refInformationPopup = React.createRef<mobiscroll.PopupComponent>()

  private generateId(length: number) {
    let result = ''
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  private generateNFreePasses = (n: number) =>
    Array(n)
      .fill(1)
      .map((): FreeTicket => {
        const rndKey = this.generateId(8)
        return { 'Trans-Nr': rndKey }
      })

  private createNewTickets = (amount: number) => {
    const newPasses = this.generateNFreePasses(amount)
    const { paywall } = this.props
    this.props.setPaywall(paywall.price, [...newPasses])
  }

  formatMoney = (number: number) => {
    return number.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
  }

  showFreeTicketList = () => {
    this.refInformationPopup.current?.instance.show()
  }

  render() {
    return (
      <div className='pw-container'>
        <mobiscroll.Popup
          ref={this.refInformationPopup}
          display='center'
          buttons={[{ text: i18n.t('general.back'), handler: 'cancel' }]}
        >
          <div className='mbsc-padding' style={{ maxWidth: '500px' }}>
            <div className='mbsc-align-center'>
              <h3 style={{ marginTop: '0' }}>{i18n.t('recordingPlan.paywall.formPay.infoPopup.headText')}</h3>
            </div>
            <div style={{ lineHeight: '1.5', textAlign: 'justify' }}>
              {i18n.t('recordingPlan.paywall.formPay.infoPopup.contentText')}
            </div>
          </div>
        </mobiscroll.Popup>

        <div className='container-paywall'>
          <div className='paywall-agbBox'>
            <StaigeCheckbox
              checked={this.props.paywallAGB}
              handleClick={() => {
                const check = !this.props.paywallAGB
                if (!check) {
                  this.props.setPaywall(0, [])
                }

                this.props.handlePaywallAGB(check)
              }}
              label={
                <div>
                  {i18n.t('matchEditor.stepEvent.settings.paywall.agb1')}{' '}
                  <a className='agbBox-a' onClick={() => this.showFreeTicketList()}>
                    {i18n.t('matchEditor.stepEvent.settings.paywall.agb2')}
                  </a>
                </div>
              }
            />
          </div>

          {!this.props.paywallDataLoaded ? (
            <div style={{ margin: 40 }}>
              <LoadingSpinner />
            </div>
          ) : (
            <div className='paywall-cardWrapper'>
              <div className='cardWrapper-card' style={!this.props.paywallAGB ? { opacity: 0.2 } : {}}>
                <div className='card-header'>
                  <mobiscroll.Input
                    type='number'
                    lang={checkLanguages()}
                    value={this.props.paywall.price}
                    min={1.9}
                    max={20}
                    icon={'fa-euro'}
                    onChange={(v) => this.setPrice(Number(v.target.value))}
                    disabled={!this.props.paywallAGB}
                  >
                    {i18n.t('recordingPlan.paywall.formPay.contentText') + '*'}
                  </mobiscroll.Input>
                  <div className='header-tags'>
                    <StaigeTag
                      disabled={!this.props.paywallAGB}
                      active={this.props.paywall.price === 3}
                      label='3 EUR'
                      onClick={() => this.setPrice(3)}
                    />
                    <StaigeTag
                      disabled={!this.props.paywallAGB}
                      active={this.props.paywall.price === 5}
                      label='5 EUR'
                      onClick={() => this.setPrice(5)}
                    />
                    <StaigeTag
                      disabled={!this.props.paywallAGB}
                      active={this.props.paywall.price === 10}
                      label='10 EUR'
                      onClick={() => this.setPrice(10)}
                    />
                  </div>
                </div>
                <div className='card-vat'>{i18n.t('recordingPlan.paywall.formPay.vat')}</div>
              </div>

              <div className='cardWrapper-card' style={!this.props.paywallAGB ? { opacity: 0.2 } : {}}>
                <div className='card-header'>
                  <mobiscroll.Input
                    type='number'
                    lang={checkLanguages()}
                    value={this.props.paywall.freeTickets.length}
                    step={1}
                    min={0}
                    max={50}
                    onChange={(v) => this.createNewTickets(Number(v.target.value))}
                    disabled={!this.props.paywallAGB}
                  >
                    Freikarten
                  </mobiscroll.Input>
                  <div className='header-tags'>
                    <StaigeTag
                      disabled={!this.props.paywallAGB}
                      active={this.props.paywall.freeTickets.length === 10}
                      label='10 Tickets'
                      onClick={() => this.createNewTickets(10)}
                    />
                    <StaigeTag
                      disabled={!this.props.paywallAGB}
                      active={this.props.paywall.freeTickets.length === 20}
                      label='20 Tickets'
                      onClick={() => this.createNewTickets(20)}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}
