import React from 'react'
import i18n from 'i18next'
import HeaderWrapper from '../../helper/PageWrapper'

import { Link } from 'react-router-dom'

import './Administration.scss'

type SupportPropTypes = {
  nothing?: unknown
}

const menuItem2 = function (title: string, supTitle: string, linkTo: string) {
  return (
    <Link to={linkTo} className='column-box'>
      <div className='box-content'>
        <div className='content-title'>{title}</div>
        <div className='content-supTitle'>{supTitle}</div>
      </div>

      <div className='box-icon'>
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
          <mask id='mask0_693_13796' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'>
            <rect width='24' height='24' fill='#F6F6F6' />
          </mask>
          <g mask='url(#mask0_693_13796)'>
            <path d='M15 19L13.6 17.6L18.2 13H2V11H18.2L13.6 6.4L15 5L22 12L15 19Z' fill='#F6F6F6' />
          </g>
        </svg>
      </div>
    </Link>
  )
}

export default class Support extends React.Component<SupportPropTypes, unknown> {
  render() {
    return (
      <HeaderWrapper headerTitle={i18n.t('administration.title')}>
        <div className='page-administration'>
          <div className='main-container'>
            {menuItem2(
              i18n.t('administration.userManagement.menuTitle'),
              i18n.t('administration.userManagement.menuSupTitle'),
              '/administration/usermanagement'
            )}
            {menuItem2(
              i18n.t('administration.contractStramingAbo.menuTitle'),
              i18n.t('administration.contractStramingAbo.menuSupTitle'),
              '/administration/subscriptionmanagement'
            )}
          </div>
        </div>
      </HeaderWrapper>
    )
  }
}
