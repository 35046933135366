import React, { ChangeEvent } from 'react'
import mobiscroll from '@mobiscroll/react'
import i18n from 'i18next'
import * as mobiscrollV5 from '@mobiscroll/react5'
import { checkLanguages } from '../../helper/checkLanguages/checkLanguages'

import { CityContainer } from '../../../services/cityContainer'
import './cityForm.scss'
import { CityData } from '../../../types/CityData'
import AiswSpinner from '../../helper/aiswSpinner/aiswSpinner'

type CityFormProps = {
  id?: string
  disabled?: boolean
  cityService: CityContainer
  open: boolean
  onClose: (city?: CityData) => void
}
type CityFormState = {
  loading: boolean
  name: string
  state: string
  stateShort: string
  country: string
  clubs: number
}

const EmptyState = {
  open: false,
  loading: false,
  name: '',
  state: '',
  stateShort: '',
  country: '',
  clubs: 0
}

export default class CityForm extends React.Component<CityFormProps, CityFormState> {
  constructor(props: CityFormProps) {
    super(props)
    this.state = { ...EmptyState }
  }

  componentDidMount() {}

  closeModal = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  handleTextPropertyChanged = (key: keyof CityFormState) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    this.setState({ ...this.state, [key]: value })
  }

  handleCancelClick = () => {
    this.setState(EmptyState)
    this.closeModal()
  }

  validateInput = () => {
    return (
      this.state.name !== '' &&
      this.state.state !== '' &&
      this.state.stateShort !== '' &&
      this.state.country !== ''
    )
  }

  handleSaveClick = async () => {
    if (this.validateInput()) {
      this.setState({ loading: true })
      const body = {
        name: this.state.name,
        state: this.state.state,
        stateShort: this.state.stateShort,
        country: this.state.country,
        clubs: this.state.clubs
      }

      const newCity = await this.props.cityService.createCity(body)
      this.setState({ ...EmptyState })
      this.props.onClose(newCity)
    }
  }

  render() {
    const lang = checkLanguages()
    return (
      <div className='form cityForm'>
        <mobiscrollV5.Popup
          isOpen={this.props.open}
          onClose={this.closeModal}
          theme='ios'
          themeVariant='dark'
        >
          <div style={{ margin: '0 -16px' }}>
            <mobiscroll.FormGroupTitle>
              <h2>{i18n.t('forms.city.title')}</h2>
            </mobiscroll.FormGroupTitle>
            <div className='mbsc-grid mbsc-no-padding' style={{ marginTop: '-24px' }}>
              <div className='mbsc-row'>
                <div className='mbsc-col mbsc-col-6 left'>
                  <mobiscroll.Input
                    onChange={this.handleTextPropertyChanged('name')}
                    type='text'
                    placeholder={i18n.t('general.name')}
                    lang={lang}
                    value={this.state.name}
                    disabled={this.state.loading}
                  >
                    {i18n.t('general.name')}
                  </mobiscroll.Input>

                  <mobiscroll.Input
                    onChange={this.handleTextPropertyChanged('state')}
                    type='text'
                    placeholder={i18n.t('forms.city.state')}
                    lang={lang}
                    value={this.state.state}
                    disabled={this.state.loading}
                  >
                    {i18n.t('forms.city.state')}
                  </mobiscroll.Input>
                </div>
                <div className='mbsc-col mbsc-col-6 right'>
                  <mobiscroll.Input
                    onChange={this.handleTextPropertyChanged('stateShort')}
                    type='text'
                    placeholder={i18n.t('forms.city.stateShort')}
                    lang={lang}
                    value={this.state.stateShort}
                    disabled={this.state.loading}
                  >
                    {i18n.t('forms.city.stateShort')}
                  </mobiscroll.Input>
                  <mobiscroll.Input
                    onChange={this.handleTextPropertyChanged('country')}
                    type='text'
                    placeholder={i18n.t('forms.city.country')}
                    lang={lang}
                    value={this.state.country}
                    disabled={this.state.loading}
                  >
                    {i18n.t('forms.city.country')}
                  </mobiscroll.Input>
                </div>
              </div>
            </div>

            <mobiscroll.FormGroup style={{ marginBottom: '10px' }}>
              <div className='mbsc-grid'>
                <div className='mbsc-row'>
                  <div className='mbsc-col mbsc-col-xl-6' style={{ paddingRight: '0.3em' }}>
                    <mobiscroll.Button
                      onClick={this.handleCancelClick}
                      className={'round blue fullWidth'}
                      disabled={this.state.loading}
                      style={{ height: '36px', padding: '0', lineHeight: '0' }}
                    >
                      {i18n.t('general.cancel')}
                    </mobiscroll.Button>
                  </div>
                  <div className='mbsc-col mbsc-col-xl-6' style={{ paddingLeft: '0.3em' }}>
                    <mobiscroll.Button
                      onClick={this.handleSaveClick}
                      className={'round blue fullWidth'}
                      disabled={this.state.loading || !this.validateInput()}
                      style={{ height: '36px', padding: '0', lineHeight: '0' }}
                    >
                      {this.state.loading ? (
                        <div style={{ height: '18px', width: '18px', marginLeft: '46%' }}>
                          <AiswSpinner />
                        </div>
                      ) : (
                        i18n.t('general.save')
                      )}
                    </mobiscroll.Button>
                  </div>
                </div>
              </div>
            </mobiscroll.FormGroup>
          </div>
        </mobiscrollV5.Popup>
      </div>
    )
  }
}
