import * as Discovery from '@soccerwatch/discovery'
import * as Helper from '../helper/GlobalHelper'
import { CameraSpeedTestResponse, CornerPointsResponse, CameraData } from '@soccerwatch/common'

export type HomePosition = {
  grid_pos: [number, number]
  fov: number
}

export type HomePositionStatusResponse = {
  status: number
}

export const getCameraSystem = async (
  cameraId: number | string,
  callAs: string,
  retry = 5
): Promise<CameraData> => {
  try {
    return await Helper.apiGet<CameraData>(`${Discovery.API_CAMERA}/info/single/${cameraId}${callAs}`)
  } catch (error) {
    if (retry > 0) {
      await new Promise((resolve) => {
        setTimeout(resolve, 50 + Math.random() * 1000)
      })
      return getCameraSystem(cameraId, callAs, retry - 1)
    } else {
      throw error
    }
  }
}

export const getCameraHomePosition = async (cameraId: string, callAs: string) => {
  return await Helper.apiGet<HomePosition | HomePositionStatusResponse>(
    `${Discovery.API_CAMERA}/info/homePosition/${cameraId}${callAs}`
  )
}

export const setCameraHomePosition = async (cameraId: string, homePosition: HomePosition, callAs: string) => {
  return await Helper.apiPost<HomePosition>(
    `${Discovery.API_CAMERA}/info/homePosition/${cameraId}${callAs}`,
    homePosition
  )
}

export const updateCameraHomePositionName = async (
  cameraId: string,
  homePositionName: string,
  callAs: string
) => {
  return await Helper.apiPost<HomePosition>(`${Discovery.API_CAMERA}/manage${callAs}`, {
    homePositionName,
    uid: cameraId
  })
}

export const postCameraSpeedTest = async (cameraId: number | string, callAs: string) => {
  return await Helper.apiPost<CameraSpeedTestResponse>(
    `${Discovery.API_CAMERA}/control/${cameraId}/speedTest${callAs}`
  )
}

export const getCameraCornerPointsAll = async (cameraId: number | string, callAs?: string) => {
  callAs = callAs ?? ''
  return await Helper.apiGet<CornerPointsResponse>(
    `${Discovery.API_CAMERA}/info/cornerPointsAll/${cameraId}${callAs}`
  )
}

export const getCameraCornerPointsFieldName = async (
  cameraId: number | string,
  currentFieldName: string,
  body: any,
  callAs: string
) => {
  return await Helper.apiPost<{ status: string }>(
    `${Discovery.API_CAMERA}/info/cornerPoints/${cameraId}/${currentFieldName}${callAs}`,
    body
  )
}

export const deleteCameraCornerPoints = async (
  cameraId: number | string,
  fieldName: string,
  callAs: string
) => {
  return await Helper.apiDelete<{ status: number }>(
    `${Discovery.API_CAMERA}/info/cornerPoints/${cameraId}/${fieldName}${callAs}`
  )
}
