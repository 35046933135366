import React from 'react'
import i18n from 'i18next'
import AiswSpinner from '../../helper/aiswSpinner/aiswSpinner'

import locatioOnIcon from '../../../img/icons/location_on.svg'
import camK1 from '../../../img/k1.png'
import camK1_K2 from '../../../img/k2_.png'
import camK2M from '../../../img/k2m.png'
import camIp from '../../../img/ip.png'
import camK2X from '../../../img/k2x.png'

import './Camera.scss'
import StaigeButton from '../../../components/styles/Button'

type CameraStepProps = {
  camera: string | undefined
  cameraData: Array<{ value: string; address: string; id: string; hardwarePlatform?: string }>
  onSetCamera: (RowKey: string, address: string) => void
  selectedField: string
  availableFields: Array<{ value: string; text: string }>
  handleSetFieldName: (value: string) => void
  disabled: boolean
  cameraLoading: boolean
  isNew: boolean
}
type CameraStepState = {
  empty: true
}
export class CameraStep extends React.Component<CameraStepProps, CameraStepState> {
  render() {
    if (!this.props.cameraLoading) {
      return (
        <div className='main-camera' style={{ alignItems: 'center' }}>
          <AiswSpinner className='inline' />
        </div>
      )
    }

    return (
      <div className='main-camera'>
        <div className='camera-wrapper'>
          <div className='wrapper-titel'>{i18n.t('matchEditor.stepCamera.cameraList.title')}</div>
          <div className='wrapper-cams'>
            {this.props.cameraData.map((cam, index) => {
              const active = this.props.camera === cam.value
              let cameraImg
              switch (cam.hardwarePlatform) {
                case 'ip':
                case 'qube':
                  cameraImg = (
                    <div className='tag-img'>
                      <img className='imgIp' src={camIp} />
                    </div>
                  )
                  break
                case 'k1':
                  cameraImg = (
                    <div className='tag-img'>
                      <img className='imgK1' src={camK1} />
                    </div>
                  )
                  break
                case 'k2m':
                  cameraImg = (
                    <div className='tag-img'>
                      <img className='imgK2m' src={camK2M} />
                    </div>
                  )
                  break
                case 'k2x':
                  cameraImg = (
                    <div className='tag-img'>
                      <img className='imgK2x' src={camK2X} />
                    </div>
                  )
                  break
                default:
                  cameraImg = (
                    <div className='tag-img'>
                      <img src={camK1_K2} />
                    </div>
                  )
                  break
              }

              return (
                <div
                  className={`cam-tag ${active ? 'cam-tagActive' : ''}`}
                  key={index}
                  onClick={() => (this.props.disabled ? null : this.props.onSetCamera(cam.id, cam.address))}
                >
                  {cameraImg}
                  <div className='tag-content'>
                    <div className='content-titel'>
                      {i18n.t('matchEditor.stepCamera.cameraList.title')} {cam.value}
                    </div>
                    <div className='content-location'>
                      <div className='location-icon'>
                        <img src={locatioOnIcon} />
                      </div>
                      <div className='location-text'>{cam.address}</div>
                    </div>
                  </div>
                  <div className='tag-btn'>{active && <div className='btn-radio' />}</div>
                </div>
              )
            })}
          </div>
        </div>
        <div className='camera-fieldmask'>
          <div className='fieldmask-titel'>{i18n.t('matchEditor.stepCamera.fieldmask.title')}</div>
          <div className='fieldmask-tags'>
            {this.props.availableFields.map((field, index) => {
              const active = this.props.selectedField === field.value

              return (
                <div
                  className={`tags-tag ${active ? 'tags-tagActive' : ''}`}
                  key={index}
                  onClick={() => (this.props.disabled ? null : this.props.handleSetFieldName(field.value))}
                >
                  <div className='tag-text'>{field.text}</div>
                </div>
              )
            })}
            <StaigeButton
              style='ghost'
              label={i18n.t('matchEditor.stepCamera.fieldmask.createNewFieldmask')}
              linkTo={`/cameramonitor/liveview/${this.props.camera}`}
            />
          </div>
        </div>
      </div>
    )
  }
}
