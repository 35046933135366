import React from 'react'
import mobiscroll from '@mobiscroll/react'
import PageWrapper from '../../helper/PageWrapper'
import i18 from 'i18next'
import StaigeButton from '../../components/styles/Button'
import i18n from '../../languages/i18n'
import Tipps from './container/tipps'

import contractContainer, { ContractContainer } from '../../services/contractContainer'
import { RouteComponentProps } from 'react-router'
import { isAxiosError } from '../../helper/UtilityFunctions'
import { BrowserInfoContainer } from '../../services/browserInfoContainer'
import { CameraData, Role } from '@soccerwatch/common'
import { searchAndSortGetCallAs } from '../../services/serviceHelper'
import { getCameraCornerPointsAll, getCameraCornerPointsFieldName } from '../../api/api-camera'
import { PanoramaPlayer } from '../../components/panoramaPlayer/player'
import { LivekitContainer } from '../../services/livekitContainer'

import './FieldMask.scss'
import StaigeModal from '../../components/styles/Modal'

export type GridPos = [number, number]

type FieldMaskPageProps = RouteComponentProps & {
  cameraId: string
  camera: CameraData
  contractContainer: ContractContainer
  fieldMask?: {
    lastCalibration: unknown
  }
  browserContainer: BrowserInfoContainer
  fieldName?: string
  isNew?: boolean
  livekitContainer: LivekitContainer
  homePositionActivated?: boolean
}

type FieldMaskStateType = {
  fieldNames: string
  currentHomePosition: { grid_pos: GridPos; fov: number }
  currentCorners: number[]
  currentCornersBackup: number[]
  currentFieldName: string

  save: boolean
  saveHomePosition: boolean
  uploadCurners: boolean
  setYaml: boolean
}

const defaultCorners: number[] = [460, 973, 1246, 1604, 2610, 312, 3388, 2246]
const defaultHomePosition: GridPos = [960, 3200]
const defaultFov = -1

export default class FieldMaskPage extends React.Component<FieldMaskPageProps, FieldMaskStateType> {
  private readonly refWrongCameraVersion = React.createRef<any>()

  constructor(props: FieldMaskPageProps) {
    super(props)
    this.state = {
      fieldNames: 'default',
      currentCorners: defaultCorners,
      currentCornersBackup: defaultCorners,
      currentHomePosition: { grid_pos: defaultHomePosition, fov: defaultFov },
      currentFieldName: '',
      save: false,
      saveHomePosition: false,
      uploadCurners: false,
      setYaml: false
    }
  }

  componentDidMount = () => {
    const { camera } = this.props
    this.getCornerPoints()
    if (camera.detail && typeof camera.detail === 'object') {
      //@ts-ignore
      const version = camera.detail.packages['system-packages']?.version || '0.0.0'
      if (this.checkCameraVersions(version, '0.2.25')) {
        this.refWrongCameraVersion.current.handleOpenModal()
      }
    }
  }

  checkCameraVersions(version: string, needVersion: string) {
    const camVersion = version.split('.').map(Number)
    const camNeedVersion = needVersion.split('.').map(Number)
    const maxLength = Math.max(camVersion.length, camNeedVersion.length)

    for (let i = 0; i < maxLength; i++) {
      const numA = camVersion[i] || 0
      const numB = camNeedVersion[i] || 0
      if (numA > numB) {
        return false // version is higher
      }
      if (numA < numB) {
        return true // version is lower
      }
    }
    return false // Both version same
  }

  getCornerPoints = async () => {
    try {
      const callAs = searchAndSortGetCallAs([
        Role.admin,
        contractContainer.getHighestRelevantRoleForCurrentContract()
      ])
      const res = await getCameraCornerPointsAll(this.props.cameraId, callAs)
      if (res.status === 200) {
        let fieldNames: string = this.props.fieldName ?? ''
        let fieldCorners: number[] = []
        let homePositions: { grid_pos: GridPos; fov: number } = this.state.currentHomePosition

        res.data.forEach((dataset) => {
          if (!dataset.Corners.corners.length) {
            dataset.Corners.corners = JSON.parse(JSON.stringify(defaultCorners))
          }
          if (this.props.fieldName === dataset.fieldName) {
            fieldNames = dataset.fieldName
            fieldCorners = dataset.Corners.corners

            //@ts-ignore
            const homePosition: { grid_pos: GridPos; fov: number } = dataset.HomePosition

            homePositions.fov = homePosition?.fov && homePosition.fov > 5 ? homePosition.fov : defaultFov
            homePositions.grid_pos =
              homePosition?.grid_pos && homePosition.grid_pos.length === 2
                ? homePosition.grid_pos
                : defaultHomePosition
          }
        })

        this.setState({
          currentFieldName: fieldNames,
          currentCorners: fieldCorners.length <= 0 ? defaultCorners : fieldCorners,
          currentCornersBackup: fieldCorners.length <= 0 ? defaultCorners : [...fieldCorners],
          currentHomePosition: homePositions,
          setYaml: true
        })
        return
      }
      console.warn('Get Cornerpoints for', this.props.cameraId, ' Failed with Status', res.status)
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        console.warn('Get Cornerpoints for', this.props.cameraId, ' Failed with Status', err.response?.status)
      } else {
        console.warn('An unknown error occurred:', err)
      }
    }
  }

  renderBottomBtns = () => {
    return (
      <StaigeButton
        label={this.props.isNew ? i18n.t('general.create') : i18n.t('general.save')}
        style='white'
        iconMargin='0 -4px 0 4px'
        disabled={
          (!this.state.save && !this.state.saveHomePosition) ||
          this.state.uploadCurners ||
          this.state.currentFieldName === '' ||
          this.state.currentFieldName === undefined
        }
        onClick={() => this.uploadCornerPoints()}
      />
    )
  }

  setCurnerPoints = (curnerPoints: Array<number>, save: boolean) => {
    this.setState({
      currentCorners: curnerPoints,
      save: save
    })
  }

  setHomePosition = (homePosition: GridPos) => {
    this.setState({
      currentHomePosition: { grid_pos: homePosition, fov: this.state.currentHomePosition.fov },
      saveHomePosition: true
    })
  }

  setFov = (fov: number) => {
    this.setState({
      currentHomePosition: { grid_pos: this.state.currentHomePosition.grid_pos, fov: fov },
      saveHomePosition: true
    })
  }

  setResetHomePosition = (homePosition: GridPos, homePositionFieldOfView: number) => {
    this.setState({
      currentHomePosition: { grid_pos: homePosition, fov: homePositionFieldOfView },
      saveHomePosition: false
    })
  }

  uploadCornerPoints = async () => {
    this.setState({ uploadCurners: true })
    const { currentFieldName, currentCorners, currentHomePosition } = this.state

    let newCorner: Array<number> = []
    for (const [key, value] of Object.entries(currentCorners)) {
      newCorner.push(Math.round(value))
    }

    let body = { Corners: { corners: newCorner }, HomePosition: currentHomePosition }
    const callAs = searchAndSortGetCallAs([
      Role.admin,
      contractContainer.getHighestRelevantRoleForCurrentContract()
    ])
    const res = await getCameraCornerPointsFieldName(this.props.cameraId, currentFieldName, body, callAs)
    this.setState({ uploadCurners: false })
    if (res.status === 'Ok') {
      this.props.history.push(`/cameramonitor/${this.props.cameraId}`)
      return
    }
    console.error('Could not save Fieldmask:', res)
    alert(i18.t('matchfieldCalibration.saveError'))
  }

  render() {
    const { save, saveHomePosition, currentFieldName, currentCorners, currentHomePosition, setYaml } =
      this.state

    return (
      <PageWrapper
        headerTitle={`${i18n.t('fieldMask.headerTitleLoad')} ${this.props.camera?.hardwarePlatform} – ${
          this.props.camera?.RowKey
        }`}
        linkTo={`/cameramonitor/${this.props.camera.RowKey}`}
        simpleTopbar
        bottomBar={save || saveHomePosition ? this.renderBottomBtns : undefined}
      >
        <StaigeModal
          ref={this.refWrongCameraVersion}
          onConfirm={() => {}}
          confirmText={i18n.t('general.close')}
          title={i18n.t('fieldMask.tooltipVersion.title')}
          text={i18n.t('fieldMask.tooltipVersion.text')}
          disableCancleBtn
          closeOutsideClick
        />
        <div>
          <div className='page-fieldMask'>
            <div className='fieldMask-container'>
              <div className='container-content'>
                <div className='content-header'>
                  <div className='header-title'>
                    {this.props.isNew
                      ? i18n.t('fieldMask.headerTitleNew')
                      : i18n.t('fieldMask.headerTitleEdit')}
                  </div>
                </div>
                <div className='content-bottom'>
                  <div className='bottom-input'>
                    <mobiscroll.Input
                      type={'text'}
                      value={currentFieldName}
                      disabled={!this.props.isNew}
                      onChange={(v) =>
                        this.setState({
                          currentFieldName: String(v.target.value).trim()
                        })
                      }
                      placeholder='Hauptfeld - 11er-Fußball...'
                      valid={true}
                    >
                      {i18n.t('fieldMask.inputFieldMaskTitle')}
                    </mobiscroll.Input>
                  </div>
                  <div className='bottom-fieldmaskContainer'>
                    <div className='fieldmaskContainer-header'>
                      <div className='header-title'>{i18n.t('fieldMask.fieldmaskContainerTitle')}</div>
                    </div>
                    <div className='dev-ccc '>{i18n.t('fieldMask.fieldmaskContainerInfo')}</div>

                    <Tipps />

                    <div className='fieldmaskContainer-content'>
                      <div className='content-prev'>
                        {setYaml && (
                          <PanoramaPlayer
                            cameraId={this.props.cameraId}
                            currentCorners={currentCorners}
                            currentCornersBackup={[...this.state.currentCornersBackup]}
                            currentHomePosition={currentHomePosition}
                            livekitContainer={this.props.livekitContainer}
                            setCurnerPoints={this.setCurnerPoints}
                            setHomePosition={this.setHomePosition}
                            setFov={this.setFov}
                            setResetHomePosition={this.setResetHomePosition}
                            save={save || saveHomePosition}
                            onUploadFile={this.uploadCornerPoints}
                            homePositionActivated={this.props.homePositionActivated}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    )
  }
}
