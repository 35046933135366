import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import registerServiceWorker from './registerServiceWorker'

import './lib/mobiscroll5_14/mobiscroll.react.scss'
import './lib/mobiscroll/mobiscroll.react.scss'
import './languages/i18n'

import Discovery from './services/discovery'

Discovery.then(() => {
  ReactDOM.render(<App />, document.getElementById('root'))
  registerServiceWorker()
}).catch((err) => {
  window.alert(err)
  console.error(err)
})
