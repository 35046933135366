import React, { useCallback, useEffect, useState } from 'react'
import mobiscroll from '@mobiscroll/react'
import i18n from 'i18next'
import HeaderWrapper from '../../helper/PageWrapper'
import AiswSpinner from '../helper/aiswSpinner/aiswSpinner'
import StaigeButton from '../../components/styles/Button'
import deleteIcon from '../../img/icons/delete.svg'
import StaigeSwitch from '../../components/styles/Switch'
import StaigeModal from '../../components/styles/Modal'

import PropTypes, { InferProps, Validator } from 'prop-types'
import { useHistory, useRouteMatch } from 'react-router-dom'
import {
  emptyImageAdMaterial,
  emptyLottyAdMaterial,
  emptyStaticImageAdMaterial
} from './forms/EmptyAdvertisements'
import { isValidAdMaterial, validateURL } from './forms/Validators'
import { AdvertisementContainer } from '../../services/advertisementContainer'
import { PickPropertiesWithType, InstreamAd, InstreamTypes, LottyAd } from '@soccerwatch/common'
import { ContractContainer } from '../../services/contractContainer'
import { ClubContainer } from '../../services/clubContainer'
import { useUploadedFileURLDirekt } from '../../helper/UploadFile'
import { PreviewVideo } from './forms/prevVideo'
import { BasedType } from './forms/basedType'
import { MainSettings } from './forms/mainSettings'

import './EditSponsors.scss'

const propTypes = {
  new: PropTypes.bool,
  advertisementContainer: PropTypes.any.isRequired as Validator<AdvertisementContainer>,
  contractContainer: PropTypes.any.isRequired as Validator<ContractContainer>,
  containerLoading: PropTypes.bool,
  clubContainer: PropTypes.any.isRequired as Validator<ClubContainer>
}
export const useChangeHandlers = <T extends Record<string, unknown>>(
  obj: T,
  setObj: (newObject: T) => void
) => {
  const textInputChangeHandler = (propertyName: keyof PickPropertiesWithType<T, string>) =>
    useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setObj({
          ...obj,
          [propertyName]: e.target.value
        })
      },
      [propertyName, obj]
    )

  const switchInputChangeHandler = (propertyName: keyof PickPropertiesWithType<T, boolean>) =>
    useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setObj({
          ...obj,
          [propertyName]: e.target.checked
        })
      },
      [propertyName, obj]
    )

  const setDateProperty = (propertyName: keyof PickPropertiesWithType<T, Date>) =>
    useCallback(
      (newDate: Date) => {
        setObj({
          ...obj,
          [propertyName]: newDate
        })
      },
      [propertyName, obj]
    )

  const setArrayProperty = (propertyName: keyof PickPropertiesWithType<T, any>) =>
    useCallback(
      (valueArray: any) => {
        setObj({
          ...obj,
          [propertyName]: valueArray.value
        })
      },
      [propertyName, obj]
    )

  return { textInputChangeHandler, switchInputChangeHandler, setDateProperty, setArrayProperty }
}

export const EditAdvertisement = (props: InferProps<typeof propTypes>) => {
  const history = useHistory()
  const goBack = useCallback(() => {
    history.replace('/advertisement')
  }, [history])
  const routeMatch = useRouteMatch<{
    adType: InstreamTypes
    adID: string
  }>()
  const [newImg, setNewImg] = useState<boolean | File>(false)

  const refConfirmDeleteModal = React.createRef<any>()

  let defaultAdMaterial: InstreamAd | LottyAd

  switch (routeMatch.params.adType) {
    case InstreamTypes.LOTTY:
      defaultAdMaterial = emptyLottyAdMaterial
      break
    case InstreamTypes.IMAGE:
      defaultAdMaterial = emptyImageAdMaterial
      break
    case InstreamTypes.STATIC:
      defaultAdMaterial = emptyStaticImageAdMaterial
      break
    default:
      defaultAdMaterial = emptyImageAdMaterial
  }
  if (props.contractContainer.state.contracts.length) {
    const contract = props.contractContainer.getCurrentContract()
    defaultAdMaterial.contractId = contract?.RowKey ?? undefined
  }
  const [advertisement, setAdvertisement] = useState<InstreamAd | LottyAd | undefined>(
    props.new ? defaultAdMaterial : undefined
  )

  const [generalInfoIsValid, setGeneralInfoIsValid] = useState(true)

  const adMaterials = props.advertisementContainer.state.adMaterials
  const { updateAdMaterial, deleteAdMaterial, createAdMaterial, getAdMaterialById } =
    props.advertisementContainer

  useEffect(() => {
    if (!advertisement) {
      const ad = getAdMaterialById(routeMatch.params.adID)
      if (ad) {
        setAdvertisement({ ...ad })
      }
    }
  }, [props.containerLoading])

  const save = useCallback(async () => {
    if (advertisement) {
      const isFullAdMaterial = (ad: InstreamAd): ad is InstreamAd => Boolean(ad.RowKey)
      if (isValidAdMaterial(advertisement)) {
        const currentContract = props.contractContainer.getCurrentContract()
        if (!currentContract) {
          return console.error(
            '<EditAdvertisement> Can not create new Ad. No Contract selected. This should not happen'
          )
        }
        advertisement.contractId = currentContract.RowKey

        if (typeof newImg !== 'boolean') {
          const fileURL = await useUploadedFileURLDirekt(newImg)
          advertisement.mediaInstream = String(fileURL)
        }

        if (props.new) {
          await createAdMaterial(advertisement)
        } else if (isFullAdMaterial(advertisement)) {
          await updateAdMaterial(advertisement)
        }
      }
    }

    goBack()
  }, [createAdMaterial, updateAdMaterial, props.new, advertisement, adMaterials])

  const deleteAd = useCallback(async () => {
    if (!props.new && advertisement) {
      onIsActiveChange(false)
      await save()
      await deleteAdMaterial(advertisement as InstreamAd)
      goBack()
    }
  }, [adMaterials, advertisement, goBack])

  const [nameIsValid, setNameIsValid] = useState<boolean>(true)
  const [urlIsValid, setUrlIsValid] = useState<boolean>(true)

  function onClickTest() {
    let checkName, checkUrl
    if (advertisement && advertisement.name.length <= 0) {
      setNameIsValid(false)
      checkName = false
    } else {
      setNameIsValid(true)
      checkName = true
    }

    if (advertisement && !validateURL(advertisement.link)) {
      setUrlIsValid(false)
      checkUrl = false
    } else {
      setUrlIsValid(true)
      checkUrl = true
    }

    if (!checkName || !checkUrl) {
      mobiscroll.toast({ color: 'warning', message: i18n.t('editAdvertisement.isValidErrMsg') })
    }

    // @ts-ignore-next-line
    if (checkName && checkUrl && (newImg || advertisement.mediaInstream)) {
      save()
    }
  }

  function urltoFile(url: string, filename: string, mimeType: string) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer()
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType })
      })
  }

  const onGeneralInfoChange = (newAd: InstreamAd) => {
    setAdvertisement({ ...newAd })
  }

  const onIsActiveChange = (value: boolean) => {
    const ad = advertisement
    if (ad) {
      ad.active = value
      setAdvertisement({ ...ad })
    }
  }

  const renderBottomBtns = () => {
    return (
      <StaigeButton
        label={i18n.t('general.save')}
        style='white'
        iconMargin='0 -4px 0 4px'
        onClick={onClickTest}
      />
    )
  }

  if (props.containerLoading || advertisement === undefined) {
    return (
      <HeaderWrapper
        className={'edit-advertisement'}
        headerTitle={i18n.t('editAdvertisement.title')}
        linkTo='/advertisement'
        simpleTopbar
        bottomBar={renderBottomBtns}
      >
        <AiswSpinner />
      </HeaderWrapper>
    )
  }

  return (
    <HeaderWrapper
      className={'edit-advertisement'}
      headerTitle={i18n.t('editAdvertisement.title')}
      linkTo='/advertisement'
      simpleTopbar
      bottomBar={renderBottomBtns}
    >
      <StaigeModal
        ref={refConfirmDeleteModal}
        onConfirm={deleteAd}
        confirmText={i18n.t('general.delete') + ' ' + i18n.t('general.confirm')}
        title={i18n.t('sponsors.deleteModal.title')}
        text={i18n.t('sponsors.deleteModal.text')}
      />
      <div className='page-editSponsors'>
        <div className='editSponsors-content'>
          <div className='content-header'>
            <div className='header-titel'>
              {props.new ? i18n.t('sponsors.newAdTitle') : i18n.t('sponsors.editAdTitle')}
            </div>
            <div className='header-action'>
              <StaigeSwitch
                checked={advertisement?.active}
                handleClick={(e) => onIsActiveChange(e)}
                label={i18n.t('sponsors.activate')}
                size='medium'
              />
              <StaigeButton
                style='ghost'
                icon={deleteIcon}
                iconMargin='0 -4px 0 -4px'
                onClick={() => refConfirmDeleteModal.current.handleOpenModal()}
                disabled={props.new === true}
              />
            </div>
          </div>
          <div className='content-bottom'>
            <MainSettings
              advertisement={advertisement as InstreamAd}
              onChange={(newAd: InstreamAd) => {
                if (newAd.mediaInstream.includes('data:image/') && newAd.mediaInstream.includes('base64')) {
                  setAdvertisement({ ...advertisement, ...newAd })
                  let info = { name: 'img.png', type: 'image/png' }
                  if (newAd.mediaInstream.includes('data:image/gif')) {
                    info = { name: 'img.gif', type: 'image/gif' }
                  }

                  urltoFile(newAd.mediaInstream, info.name, info.type).then(function (file) {
                    setNewImg(file)
                    setAdvertisement({ ...advertisement, ...newAd })
                  })
                } else {
                  setAdvertisement({ ...advertisement, ...newAd })
                  setNewImg(false)
                }
              }}
              onChangeValidity={setGeneralInfoIsValid}
              contractContainer={props.contractContainer}
              clubContainer={props.clubContainer}
              isValid={{ name: nameIsValid, url: urlIsValid }}
            />

            <PreviewVideo
              advertisement={advertisement as InstreamAd}
              onChange={(newAd: InstreamAd) => {
                if (newAd.mediaInstream.includes('data:image/') && newAd.mediaInstream.includes('base64')) {
                  setAdvertisement({ ...advertisement, ...newAd })
                  let info = { name: 'img.png', type: 'image/png' }
                  if (newAd.mediaInstream.includes('data:image/gif')) {
                    info = { name: 'img.gif', type: 'image/gif' }
                  }

                  urltoFile(newAd.mediaInstream, info.name, info.type).then(function (file) {
                    setNewImg(file)
                    setAdvertisement({ ...advertisement, ...newAd })
                  })
                } else {
                  setAdvertisement({ ...advertisement, ...newAd })
                  setNewImg(false)
                }
              }}
            />

            <BasedType
              advertisement={advertisement as InstreamAd}
              onChange={onGeneralInfoChange}
              contractContainer={props.contractContainer}
              clubContainer={props.clubContainer}
            />
          </div>
        </div>
      </div>
    </HeaderWrapper>
  )
}
EditAdvertisement.propTypes = propTypes
