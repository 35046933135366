import React, { RefObject } from 'react'
import StaigeButton from './Button'
import downIcon from '../../img/icons/chevron_down.svg'

import './Dropdown.scss'

type staigeDropdownPropTypes = {
  children?: JSX.Element[]
  label: string | undefined
  style?: 'ghost' | 'primary' | 'secondary'
  size?: 'small'
  ref?: RefObject<HTMLDivElement>
  handleOptions?: (options: Array<string>) => void
  multiple?: boolean
}

type staigeDropdownStateTypes = {
  open: boolean
  options: Array<string>
}

export default class StaigeDropdown extends React.Component<
  staigeDropdownPropTypes,
  staigeDropdownStateTypes
> {
  private wrapperRef: RefObject<HTMLDivElement>

  constructor(props: staigeDropdownPropTypes) {
    super(props)

    this.state = {
      open: false,
      options: []
    }

    this.wrapperRef = React.createRef()
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event: MouseEvent) => {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target as Node)) {
      this.setState({ open: false })
    }
  }

  handleOpen = () => {
    this.setState({ open: !this.state.open })
  }

  handleOption = (value: string) => {
    const { handleOptions, multiple } = this.props
    let options = this.state.options

    if (multiple) {
      if (options.indexOf(value) !== -1) {
        options = options.filter((e) => e !== value)
      } else {
        options.push(value)
      }
    } else if (this.state.options[0] === value) {
      options = []
    } else {
      options = [value]
    }

    if (handleOptions) {
      handleOptions(options)
    }

    this.setState({
      options
    })
  }

  handleRemoveallOption = () => {
    const { handleOptions } = this.props
    this.setState({
      options: []
    })

    if (handleOptions) {
      handleOptions([])
    }
  }

  renderTag = () => {
    if (this.state.options.length === 0) return

    return (
      <div className='btn-tag'>
        <div className='tag-label'>{this.state.options.length}</div>
        <div className='tag-close' onClick={this.handleRemoveallOption}>
          <svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10' fill='none'>
            <mask id='mask0_4621_45558' maskUnits='userSpaceOnUse' x='0' y='0' width='10' height='10'>
              <rect width='10' height='10' fill='#D9D9D9' />
            </mask>
            <g mask='url(#mask0_4621_45558)'>
              <path
                d='M2.66683 7.91658L2.0835 7.33325L4.41683 4.99992L2.0835 2.66659L2.66683 2.08325L5.00016 4.41659L7.3335 2.08325L7.91683 2.66659L5.5835 4.99992L7.91683 7.33325L7.3335 7.91658L5.00016 5.58325L2.66683 7.91658Z'
                fill='#151315'
              />
            </g>
          </svg>
        </div>
      </div>
    )
  }

  render() {
    const { label, style, size, ref } = this.props

    return (
      <div className={'staige-dropdown'} ref={ref}>
        <StaigeButton
          label={label ?? 'Label Fehlt'}
          style={style}
          icon={downIcon}
          iconMargin={'0 -4px 0 2px'}
          iconRight
          size={size}
          onClick={this.handleOpen}
          tag={this.renderTag()}
        />

        {this.state.open && (
          <div className='dropdown-container' ref={this.wrapperRef}>
            {this.props.children &&
              this.props.children.map((child, index) => {
                return (
                  <div
                    key={index}
                    className='container-tab'
                    onClick={() => this.handleOption(child.props.children)}
                    style={
                      this.state.options.indexOf(child.props.children) !== -1
                        ? { background: 'var(--Neutral-N600, #363436)' }
                        : {}
                    }
                  >
                    {this.state.options.indexOf(child.props.children) !== -1 ? (
                      <input className='tab-active' type='checkbox' checked />
                    ) : null}
                    <div className='tap-text '>{child}</div>
                  </div>
                )
              })}
          </div>
        )}
      </div>
    )
  }
}
