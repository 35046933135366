import { InstreamAd, InstreamTypes, LottyAd } from '@soccerwatch/common'

export const emptyGeneralInfo: InstreamAd = {
  name: '',
  comment: '',
  active: true,
  link: '',
  matchSponsor: [],
  mediaInstream: '',
  instreamType: InstreamTypes.IMAGE
}

export const emptyImageAdMaterial: InstreamAd = {
  ...emptyGeneralInfo,
  instreamType: InstreamTypes.IMAGE
}

export const emptyStaticImageAdMaterial: InstreamAd = {
  ...emptyGeneralInfo,
  instreamType: InstreamTypes.STATIC
}

export const emptyLottyAdMaterial: LottyAd = {
  ...emptyGeneralInfo,
  instreamType: InstreamTypes.LOTTY,
  instreamTextA: '',
  instreamButtonTextColor: '#000000',
  instreamButtonColor: '#000000',
  instreamTextColor: '#000000',
  instreamButtonText: '',
  instreamTextB: '',
  comment: '',
  mediaLottyInstreamSide: ''
}
