import * as Discovery from '@soccerwatch/discovery'
import * as Helper from '../helper/GlobalHelper'
import { TeamData } from '../types/TeamData'

export const getListTeamsByClubId = async (clubId: string, retry = 5): Promise<TeamData[]> => {
  try {
    return await Helper.apiList<TeamData>(`${Discovery.API_TEAM}/teams/by/${clubId}?callAs=anon`)
  } catch (error) {
    if (retry > 0) {
      await new Promise((resolve) => {
        setTimeout(resolve, 50 + Math.random() * 1000)
      })
      return getListTeamsByClubId(clubId, retry - 1)
    } else {
      throw error
    }
  }
}

export const getTeamById = async (teamId: string) => {
  return await Helper.apiGet<TeamData>(`${Discovery.API_TEAM}/team/${teamId}?callAs=anon`)
}

export const postTeam = async (body: Partial<TeamData>) => {
  return await Helper.apiPost<TeamData>(`${Discovery.API_TEAM}/team`, body)
}
