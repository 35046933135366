import React, { ChangeEvent } from 'react'
import './teamForm.scss'
import mobiscroll from '@mobiscroll/react'
import i18n from 'i18next'
import * as mobiscrollV5 from '@mobiscroll/react5'
import { checkLanguages } from '../../helper/checkLanguages/checkLanguages'
import { ClubData } from '@soccerwatch/common'
import { TeamContainer } from '../../../services/teamContainer'
import { CityContainer } from '../../../services/cityContainer'
import { ClubContainer } from '../../../services/clubContainer'

import { TeamData } from '../../../types/TeamData'
import { selectTeamAgeGroups } from '../../recordingPlan/Container/teamSelectionData'
import AiswSpinner from '../../helper/aiswSpinner/aiswSpinner'

import './teamForm.scss'

type TeamFormProps = {
  id?: string
  teamContainer: TeamContainer
  clubContainer: ClubContainer
  cityContainer: CityContainer
  club?: ClubData
  open: boolean
  onClose?: (team?: TeamData) => void
}
type TeamFormState = {
  loading: boolean
  name: string
  age: string
  aliasList: string[]
  clubFormOpen: boolean
}
const EmptyState: TeamFormState = {
  loading: false,
  name: '',
  age: '',
  aliasList: [],
  clubFormOpen: false
}

export default class TeamForm extends React.Component<TeamFormProps, TeamFormState> {
  constructor(props: TeamFormProps) {
    super(props)
    this.state = EmptyState
  }

  componentDidMount() {}

  closeModal = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  handleTextPropertyChanged = (key: keyof TeamFormState) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    this.setState({ ...this.state, [key]: value })
  }

  handleAgeChanged = (event: { valueText?: string | undefined }, i: { getVal: () => string }) => {
    const age = i.getVal()
    this.setState({ age })
  }

  handleCancleClick = () => {
    this.setState(EmptyState)
    this.closeModal()
  }

  validateInput = () => {
    //TODO: Validation Code
    return this.state.name !== '' && this.state.age !== '' && this.props.club !== undefined
  }

  handleSaveClick = async () => {
    if (this.validateInput()) {
      const body = {
        name: this.state.name,
        age: this.state.age,
        clubId: this.props.club?.RowKey,
        active: true
      }

      const team = await this.props.teamContainer.createTeam(body)
      this.props.onClose && this.props.onClose(team)
    }
  }

  render() {
    const lang = checkLanguages()
    return (
      <div className='form teamForm'>
        <mobiscrollV5.Popup
          isOpen={this.props.open}
          onClose={this.closeModal}
          theme='ios'
          themeVariant='dark'
        >
          <div style={{ margin: '0 -16px' }}>
            <mobiscroll.FormGroupTitle>
              <h2 style={{ marginTop: 0 }}>{i18n.t('forms.team.title')}</h2>
            </mobiscroll.FormGroupTitle>
            <div className='mbsc-grid mbsc-no-padding' style={{ marginTop: '-24px' }}>
              <div className='mbsc-row'>
                <div className='mbsc-col mbsc-col-6 left'>
                  <mobiscroll.Input
                    onChange={this.handleTextPropertyChanged('name')}
                    type='text'
                    placeholder={i18n.t('general.name')}
                    lang={lang}
                    value={this.state.name}
                    disabled={this.state.loading}
                  >
                    {i18n.t('general.name')}
                  </mobiscroll.Input>
                </div>
                <div className='mbsc-col mbsc-col-6 right'>
                  <mobiscrollV5.Select
                    data={selectTeamAgeGroups()}
                    label={i18n.t('recordingPlan.teams.ageclass')}
                    inputProps={{
                      inputStyle: 'box',
                      labelStyle: 'stacked',
                      placeholder: i18n.t('recordingPlan.teams.placeholder.select')
                    }}
                    locale={checkLanguages() === 'de' ? mobiscrollV5.localeDe : undefined}
                    display='center'
                    itemHeight={40}
                    filter
                    disabled={this.state.loading}
                    value={this.state.age ?? undefined}
                    onChange={this.handleAgeChanged}
                    theme='windows'
                    themeVariant='dark'
                  />
                  <mobiscroll.Input disabled readOnly value={this.props.club?.name}>
                    {i18n.t('forms.team.club')}
                  </mobiscroll.Input>
                </div>
              </div>
            </div>

            <mobiscroll.FormGroup style={{ marginBottom: '10px' }}>
              <div className='mbsc-grid'>
                <div className='mbsc-row'>
                  <div className='mbsc-col mbsc-col-xl-6' style={{ paddingRight: '0.3em' }}>
                    <mobiscroll.Button
                      onClick={this.handleCancleClick}
                      className={'round blue fullWidth'}
                      style={{ height: '36px', padding: '0', lineHeight: '0' }}
                      disabled={this.state.loading}
                    >
                      {i18n.t('general.cancel')}
                    </mobiscroll.Button>
                  </div>
                  <div className='mbsc-col mbsc-col-xl-6' style={{ paddingLeft: '0.3em' }}>
                    <mobiscroll.Button
                      onClick={this.handleSaveClick}
                      className={'round blue fullWidth'}
                      disabled={this.state.loading || !this.validateInput()}
                      style={{ height: '36px', padding: '0', lineHeight: '0' }}
                    >
                      {this.state.loading ? (
                        <div style={{ height: '18px', width: '18px', marginLeft: '46%' }}>
                          <AiswSpinner />
                        </div>
                      ) : (
                        i18n.t('general.save')
                      )}
                    </mobiscroll.Button>
                  </div>
                </div>
              </div>
            </mobiscroll.FormGroup>
          </div>
        </mobiscrollV5.Popup>
      </div>
    )
  }
}
