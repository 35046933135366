import { Container } from 'unstated-typescript'
import Discovery, { assertContainerHasInitializedDiscovery } from './discovery'
import type DiscoveryType from '@soccerwatch/discovery'
import { TeamData } from '../types/TeamData'
import { ContractContainer } from './contractContainer'
import { getListTeamsByClubId, getTeamById, postTeam } from '../api/api-team'

type TeamState = {
  loadingData: boolean
  teams: TeamData[]
  teamsByClubId: Record<string, TeamData[]>
  discovery?: typeof DiscoveryType
}

export class TeamContainer extends Container<TeamState> {
  discovery?: typeof DiscoveryType
  contractContainer?: ContractContainer
  constructor() {
    super()
    this.state = {
      loadingData: true,
      teams: [],
      teamsByClubId: {},
      discovery: undefined
    }

    Discovery.then((d) => {
      this.setState({ discovery: d })
    })
  }

  initialize(contractContainer: ContractContainer) {
    this.contractContainer = contractContainer
  }

  getTeamOfClubById(teamId: string, clubId: string, sync = false) {
    if (this.state.teamsByClubId[clubId]) {
      const teams = this.state.teamsByClubId[clubId]
      return teams.find((team) => team.RowKey === teamId)
    }
    if (sync) {
      this.fetchTeamById(teamId, clubId)
      return
    }
    return this.fetchTeamById(teamId, clubId)
  }

  getTeamOfClubByIdSync(clubId: string, teamId: string) {
    return this.getTeamOfClubById(clubId, teamId, true) as TeamData | undefined
  }

  async getTeamsOfClub(clubId: string) {
    if (this.state.teamsByClubId[clubId]) {
      return this.state.teamsByClubId[clubId]
    }
    const teams = await this.fetchTeamsByClubId(clubId)
    return teams
  }

  getTeamsOfClubSync(clubId: string) {
    if (this.state.teamsByClubId[clubId]) {
      return this.state.teamsByClubId[clubId]
    }
    if (!this.state.loadingData) {
      this.fetchTeamsByClubId(clubId)
    }
    return []
  }

  async fetchTeamsByTeamList(teamList: Array<TeamData>) {
    const teamsByClubId = JSON.parse(JSON.stringify(this.state.teamsByClubId)) as Record<string, TeamData[]>
    const teams = JSON.parse(JSON.stringify(this.state.teams)) as TeamData[]
    // merge oldTeams with teamList
    const ids = new Set(teams.map((d) => d.RowKey))
    const teamsMerged = [...teams, ...teamList.filter((d) => !ids.has(d.RowKey))]

    teamList.map((team, index) => {
      if (team.clubId && !this.state.teamsByClubId[team.clubId]) {
        if (teamsByClubId[team.clubId] && teamsByClubId[team.clubId].length >= 0) {
          teamsByClubId[team.clubId].push(team)
        } else {
          teamsByClubId[team.clubId] = [team]
        }
      }
    })

    await this.setState({ teams: teamsMerged, teamsByClubId, loadingData: false })
    return { teams: teamsMerged, teamsByClubId }
  }

  async fetchTeamsByClubId(clubId: string) {
    assertContainerHasInitializedDiscovery(this)
    await this.setState({ loadingData: true })

    const teamsByClubId = JSON.parse(JSON.stringify(this.state.teamsByClubId)) as Record<string, TeamData[]>
    let teams = JSON.parse(JSON.stringify(this.state.teams)) as TeamData[]

    const teamData = await getListTeamsByClubId(clubId)

    teamsByClubId[clubId] = teamData
    teams = teams.concat(teamData)

    await this.setState({ teams, teamsByClubId, loadingData: false })
    return teamData
  }

  async fetchTeamById(teamId: string, clubId: string) {
    assertContainerHasInitializedDiscovery(this)

    await this.setState({ loadingData: true })
    const teamsByClubId = JSON.parse(JSON.stringify(this.state.teamsByClubId)) as Record<string, TeamData[]>
    const teams = JSON.parse(JSON.stringify(this.state.teams)) as TeamData[]

    const teamData = await getTeamById(teamId)
    if (!teamsByClubId[clubId]) {
      teamsByClubId[clubId] = []
    }
    teamsByClubId[clubId].push(teamData)
    teams.push(teamData)
    // * See Comment above
    await this.setState({ teams, teamsByClubId, loadingData: false })
    return teamData
  }

  createTeam = async (body: Partial<TeamData>) => {
    assertContainerHasInitializedDiscovery(this)
    const res = await postTeam(body)
    const teams = JSON.parse(JSON.stringify(this.state.teams)) as TeamData[]
    const teamsByClubId = JSON.parse(JSON.stringify(this.state.teamsByClubId)) as Record<string, TeamData[]>
    teams.push(res)
    if (!teamsByClubId[res.clubId]) {
      teamsByClubId[res.clubId] = []
    }
    teamsByClubId[res.clubId].push(res)
    await this.setState({ teams, teamsByClubId })
    return res
  }
}

const teamContainer = new TeamContainer()
export default teamContainer
