import * as Discovery from '@soccerwatch/discovery'
import * as Helper from '../helper/GlobalHelper'
import { ClubData } from '@soccerwatch/common'

export const getClubById = async (clubId: string | number, retry = 5): Promise<ClubData> => {
  try {
    return await Helper.apiGet<ClubData>(Discovery.API_CLUB + '/info/' + clubId)
  } catch (error) {
    if (retry > 0) {
      await new Promise((resolve) => {
        setTimeout(resolve, 50 + Math.random() * 1000)
      })
      return getClubById(clubId, retry - 1)
    } else {
      throw error
    }
  }
}

export const postClubById = async (body: Partial<ClubData>, callAs: string) => {
  return await Helper.apiPost<ClubData>(`${Discovery.API_CLUB}/info${callAs}`, body)
}
