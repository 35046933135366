import React from 'react'

import './Badge.scss'

type staigeBadgePropTypes = {
  title: string | undefined
  active?: boolean
  color?: 'white' | 'violet' | 'blue' | 'red' | 'rosa' | 'aqua'
}

export default class StaigeBadge extends React.Component<staigeBadgePropTypes, unknown> {
  render() {
    const { title, color, active } = this.props
    if (title === undefined || !active) {
      return null
    }

    return (
      <div className={`staige-badge ${color ? 'color-' + color : ''}`}>
        <div className='badge-title'>{title}</div>
      </div>
    )
  }
}
