import React, { Component } from 'react'
import mobiscroll from '@mobiscroll/react'

import { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router'

import { Point, Area } from 'react-easy-crop/types'
import { useUploadedFileURLClass } from '../../../helper/UploadFile'

import i18n from '../../../languages/i18n'
import moment from 'moment'

import cancel from '../../../img/icons/arrow.svg'

type InsertPropsType = RouteComponentProps & {
  postTag: (
    eventType: number,
    extraBody?: any,
    secondCurrentTime?: number,
    removeTimerDisabled?: boolean,
    tempDate?: boolean
  ) => void
  closeOverlay: () => void
  liveTagging: boolean
  data: { active: boolean; time: number | undefined; tempDate: number | undefined }
  insertObj: {
    imageFile: any
    center: boolean
    imageSrc: any
    crop: { x: number; y: number }
    zoom: number
    duration: number
    croppedImage: undefined | File
  }
  onChangeDuration: (number: any) => void
  onCropChange: (crop: Point) => void
  onZoomChange: (number: any) => void
  onPositionhange: (center: boolean) => void
  onCropComplete: (croppedArea: Area, croppedAreaPixels: Area) => void
  onFileChange: (e: any) => void
  resetInsertObj: () => void
  playerCurrentTime: number
}

class InsertOverlay extends Component<InsertPropsType> {
  // Constructor
  constructor(props: InsertPropsType) {
    super(props)
    this.state = {}
  }

  handleSetInsertTag = async (tempDate?: boolean) => {
    const uploadImgUrl = await useUploadedFileURLClass(
      (n) => {},
      this.props.insertObj.croppedImage,
      'tagContent'
    )
    const body = {
      content: uploadImgUrl,
      durationContent: this.props.insertObj.duration,
      screenPosition: this.props.insertObj.center ? 'full' : 'bottomBanner'
    }

    if (tempDate) {
      this.props.postTag(1000, body, undefined, undefined, true)
    } else {
      this.props.postTag(1000, body)
    }

    this.props.resetInsertObj()
    this.props.closeOverlay()
  }

  render() {
    return (
      <div className='tag-overlay'>
        <div style={{ height: 8 }}></div>
        <div className='overlay-container'>
          <div className='container-insert'>
            <div className='insert-exampleBtnContainer'>
              <div className='title'>
                <span>{i18n.t('tagging.insert.title')}</span>
              </div>

              <div className='ssss'>
                <button
                  className={
                    this.props.insertObj.center
                      ? 'staige-button-large staige-button-large-active'
                      : 'staige-button-large'
                  }
                  onClick={() => this.props.onPositionhange(true)}
                >
                  <div className='button-title'>
                    <div className='title'>{i18n.t('tagging.insert.buttons.full')}</div>
                  </div>
                </button>
                <button
                  className={
                    !this.props.insertObj.center
                      ? 'staige-button-large staige-button-large-active'
                      : 'staige-button-large'
                  }
                  onClick={() => this.props.onPositionhange(false)}
                >
                  <div className='button-title'>
                    <div className='title'>{i18n.t('tagging.insert.buttons.bottom')}</div>
                  </div>
                </button>
              </div>
              <div className='sdddd'>
                <div className={!this.props.insertObj.center ? 'disabled' : ''}>16 / 9</div>
                <div className={this.props.insertObj.center ? 'disabled' : ''}>16 / 3</div>
              </div>
            </div>

            <div className='img-upload'>
              <span className='uplaod-title'>{i18n.t('tagging.insert.input.title')}</span>
              <mobiscroll.Input type={'file'} onChange={this.props.onFileChange} accept={'image/*'} />
            </div>
            <div className='insert-timeslider'>
              <mobiscroll.FormGroup className='eventDetail-group'>
                <mobiscroll.Slider
                  value={this.props.insertObj.duration}
                  min={5}
                  max={120}
                  // Mobiscroll types are incorrect (yet again); onChange
                  // actually is called with the value: number directly
                  // @ts-ignore
                  onChange={this.props.onChangeDuration}
                  data-live='true'
                  className='slider'
                  step={5}
                >
                  <div className='title'>
                    <span>{i18n.t('tagging.insert.slider.title')}</span>{' '}
                    <span>
                      <span style={{ color: '#ffffffbd' }}>{this.props.insertObj.duration}</span>{' '}
                      {i18n.t('tagging.insert.slider.sec')}
                    </span>
                  </div>
                </mobiscroll.Slider>
              </mobiscroll.FormGroup>
            </div>

            <div className='gasdfasdasd'>
              <span>{i18n.t('tagging.insert.showBy')}</span>
              <span
                style={{ display: 'flex', alignItems: 'flex-end', marginBottom: '-16px', color: '#ffffffbd' }}
              >
                {moment(this.props.playerCurrentTime * 1000)
                  .utc()
                  .format('HH:mm:ss')}
              </span>
            </div>

            <button
              className='staige-button-large'
              onClick={() => this.handleSetInsertTag()}
              disabled={this.props.insertObj.imageSrc === ''}
            >
              <div className='button-title'>
                <div className='title'>{i18n.t('tagging.insert.buttons.insert')}</div>
              </div>
            </button>

            <div className='insert-button' onClick={() => this.props.closeOverlay()}>
              <div className='text'>{i18n.t('general.back')}</div>
              <div className='icon'>
                <img src={cancel} style={{ transform: 'rotate(180deg)' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(InsertOverlay)
