import firebase from 'firebase/app'
import 'firebase/auth'

import { generateApiMethodsWithDefaultHeaders } from '@soccerwatch/common'

export async function getAuthorization() {
  const headers: Record<string, string> = {}
  const userId = await firebase.auth().currentUser?.getIdToken()
  headers.Authorization = 'Bearer ' + userId
  headers['Content-Type'] = 'application/json'
  return headers
}

export async function getAuthorizationCustomToken(userId: string) {
  const headers: Record<string, string> = {}
  headers.Authorization = 'Bearer ' + userId
  headers['Content-Type'] = 'application/json'
  return headers
}

interface IStaigeData {
  RowKey: string
}

export function unique<DATA extends IStaigeData>(value: DATA, index: number, self: DATA[]) {
  return index === self.findIndex((t) => t.RowKey === value.RowKey)
}

export const { apiGet, apiDelete, apiList, apiPost } = generateApiMethodsWithDefaultHeaders(getAuthorization)

/**
 * Async/Await Wrapper for setTimeout
 * Can be used to Wait T Miliseconds before code execution continous
 * Use: await Wait(1000) // Wait 1 Second
 * @param t Time to Wait in Ms
 */
export const Wait = (T: number) => {
  return new Promise((resolve) => {
    return setTimeout(resolve, T)
  })
}

/**
 * Determines if a Date object is an `Invalid Date`.
 * Invalid dates can be created by attempting to parse an
 * invalid date string. For example, `new Date('')` will
 * return an `Invalid Date`.
 * @param date - The date to check
 */
export const isInvalidDate = (date: Date) => Number.isNaN(date.valueOf())

type DBClub = {
  RowKey: string
  name: string
  city: string
  latitude: string | number
  longitude: string | number
  thumbnail: string
  location: string
  view: number
  teams: number
  videos: number
}

// Creates a v1 conform club definition
export async function ClubFromDB(dbClub: DBClub) {
  return {
    id: dbClub.RowKey,
    name: dbClub.name,
    city: dbClub.city.toLowerCase(),
    latitude: dbClub.latitude,
    longitude: dbClub.longitude,
    thumbnail: dbClub.thumbnail,
    location: dbClub.location,
    view: dbClub.view ? dbClub.view : -1,
    teams: dbClub.teams ? dbClub.teams : -1,
    videos: dbClub.videos ? dbClub.videos : -1
  }
}

export function fromUnitVector(n: number) {
  return Math.round(n * 255)
}

export function toUnitVector(n: number) {
  return n / 255.0
}
