import React, { useEffect } from 'react'
import AiswSpinner from '../../pages/helper/aiswSpinner/aiswSpinner'
import StaigeButton from '../styles/Button'

import { GridPlayerSetup } from '@soccerwatch/common'
import { LivekitContainer } from '../../services/livekitContainer'
import { GridPos } from '../../pages/fieldmask/NewFieldMask.page'

import './player.scss'

type PanoramaPlayerProps = {
  cameraId: string
  currentCorners: number[]
  currentCornersBackup: number[]
  currentHomePosition: { grid_pos: number[]; fov: number }
  setCurnerPoints: (curnerPoints: Array<number>, save: boolean) => void
  setHomePosition: (homePosition: GridPos) => void
  setFov: (fov: number) => void
  setResetHomePosition: (homePosition: GridPos, fov: number) => void
  livekitContainer: LivekitContainer
  save: boolean
  onUploadFile: () => void
  homePositionActivated?: boolean
}

export const PanoramaPlayer = (props: PanoramaPlayerProps) => {
  const panoPlayer = React.createRef<any>()
  const [connected, setConnected] = React.useState<boolean>(false)
  const [fullscreen, setFullscreen] = React.useState<boolean>(false)
  const [hello, setHello] = React.useState<any>()
  const [type, setType] = React.useState<'player' | 'img'>('player')

  useEffect(() => {
    props.livekitContainer.livekitConnectionStart(props.cameraId)

    return () => {
      if (panoPlayer.current) {
        while (panoPlayer.current.firstChild) {
          panoPlayer.current.removeChild(panoPlayer.current.firstChild)
        }
      }
      props.livekitContainer.setReset()
    }
  }, [])

  useEffect(() => {
    const { connected, element, urlElement } = props.livekitContainer.state
    if (connected && element) {
      createPlayer('player', element)
      setType('player')
    }

    if (connected && urlElement) {
      createPlayer('img', urlElement)
      setType('img')
    }

    if (!connected) {
      if (panoPlayer.current) {
        while (panoPlayer.current.firstChild) {
          panoPlayer.current.removeChild(panoPlayer.current.firstChild)
        }
      }
    }
  }, [props.livekitContainer.state.connected])

  const createPlayer = (type: 'player' | 'img', element: HTMLMediaElement | string) => {
    if (panoPlayer.current || true) {
      const playerElement = document.createElement('staige-panorama-player') as any

      let sourceType = {}

      if (type === 'player') {
        sourceType = {
          useSource: 'player',
          playerSource: {
            video: element,
            cornerData: props.currentCorners
          }
        }
      } else {
        sourceType = {
          useSource: 'image',
          imageSource: {
            sourceImageURI: element,
            cornerData: props.currentCorners
          }
        }
      }

      if (element) {
        let trackOptions: GridPlayerSetup = {
          useSource: 'player',
          fieldSizeMeter: [60, 40],
          enableFieldMaskEdit: true,
          cameraId: props.cameraId,
          allowVr: false,

          ...sourceType,

          enableHomePosition: props.homePositionActivated,
          enableHomePositionEdit: props.homePositionActivated,
          // @ts-ignore
          homePosition: props.currentHomePosition.grid_pos,
          homePositionFieldOfView: props.currentHomePosition.fov
        }

        playerElement.options = trackOptions
        playerElement.addEventListener('hello', async (event: any) => {
          setHello(event.detail)
        })

        panoPlayer.current?.append(playerElement as unknown as Node)

        playerElement.addEventListener('onSetCornerPoints', async (event: any) => {
          if (String(event.detail) !== String(props.currentCornersBackup)) {
            props.setCurnerPoints(event.detail, true)
          } else {
            props.setCurnerPoints(event.detail, false)
          }
        })

        playerElement.addEventListener('onSetHomePosition', async (event: any) => {
          if (String(event.detail) !== String(props.currentHomePosition.grid_pos)) {
            props.setHomePosition(event.detail)
          }
        })

        playerElement.addEventListener('onSetHomePositionFov', async (event: any) => {
          if (String(event.detail) !== String(props.currentHomePosition.fov)) {
            props.setFov(event.detail)
          }
        })

        playerElement.addEventListener('onSetResetHomePosition', async (event: any) => {
          if (event.detail) {
            props.setResetHomePosition(event.detail.homePosition, event.detail.homePositionFieldOfView)
          }
        })

        setConnected(true)
      }
    }
  }

  return (
    <div className='comp-panoPlayer'>
      {!connected && (
        <div className='player-loading'>
          <AiswSpinner />
          <div className='loading-text'>Connection</div>
        </div>
      )}

      <div
        className={fullscreen ? 'panoPlayer-b panoPlayer-fullscreen' : 'panoPlayer-b'}
        style={!props.livekitContainer.state.connected ? { display: 'none' } : {}}
      >
        <div className='player-action' style={connected ? {} : { display: 'none' }}>
          <div className='action-container'>
            <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
              <mask id='mask0_2382_30246' maskUnits='userSpaceOnUse' x='0' y='0' width='12' height='12'>
                <rect width='12' height='12' fill='#D9D9D9' />
              </mask>
              <g mask='url(#mask0_2382_30246)'>
                <path
                  d='M2.5 10.5C2.225 10.5 1.98958 10.4021 1.79375 10.2063C1.59792 10.0104 1.5 9.775 1.5 9.5V2.5C1.5 2.225 1.59792 1.98958 1.79375 1.79375C1.98958 1.59792 2.225 1.5 2.5 1.5H9.5C9.775 1.5 10.0104 1.59792 10.2063 1.79375C10.4021 1.98958 10.5 2.225 10.5 2.5V9.5C10.5 9.775 10.4021 10.0104 10.2063 10.2063C10.0104 10.4021 9.775 10.5 9.5 10.5H2.5ZM2.5 9.5H9.5V2.5H2.5V9.5ZM3 8.5H9L7.125 6L5.625 8L4.5 6.5L3 8.5Z'
                  fill='#999999'
                />
              </g>
            </svg>
            <div>{type === 'player' ? 'Live' : 'Bild'}</div>
          </div>
          <div>
            <button className='btn-fullscreen' onClick={() => setFullscreen(!fullscreen)}>
              {!fullscreen ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                >
                  <path
                    d='M2.25 15.75V9.75003H3.75V13.2L13.2 3.75003H9.75V2.25003H15.75V8.25003H14.25V4.80003L4.8 14.25H8.25V15.75H2.25Z'
                    fill='#F6F6F6'
                  />
                </svg>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                >
                  <path
                    d='M2.55 16.5L1.5 15.45L6.45 10.5H3V9H9V15H7.5V11.55L2.55 16.5ZM9 9V3H10.5V6.45L15.45 1.5L16.5 2.55L11.55 7.5H15V9H9Z'
                    fill='#F6F6F6'
                  />
                </svg>
              )}
            </button>
          </div>
        </div>

        <div
          className='player-action player-actionSave'
          style={props.save && fullscreen ? {} : { display: 'none' }}
        >
          <div className='action-container'>
            <StaigeButton
              label='Speichern'
              style='white'
              size='medium'
              onClick={() => props.onUploadFile()}
            />
          </div>
        </div>

        <div style={{ height: '100%' }} ref={panoPlayer} />
      </div>
    </div>
  )
}
