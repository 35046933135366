import React, { useState } from 'react'
import StaigeButton from '../../../../components/styles/Button'
import StaigeCheckbox from '../../../../components/styles/Checkbox'
import StaigeInput from '../../../../components/styles/Input'
import PageWrapper from '../../../../helper/PageWrapper'
import i18n from '../../../../languages/i18n'
import AiswSpinner from '../../../helper/aiswSpinner/aiswSpinner'

import { UserContainer } from '../../../../services/userContainer'
import { useHistory } from 'react-router-dom'
import { postContingentCancel, postContingentCancelReason } from '../../../../api/backstaige_v2'
import { ContractContainer } from '../../../../services/contractContainer'

import './AboUpgrade.scss'

type AboDowngradePageProps = {
  userContainer: UserContainer
  contractContainer: ContractContainer
}

const listOption = [
  { label: i18n.t('aboDowngrade.form.option.changeAbo'), value: 'changeAbo' },
  { label: i18n.t('aboDowngrade.form.option.notWorkingAbo'), value: 'notWorkingAbo' },
  { label: i18n.t('aboDowngrade.form.option.onlyForOneEvent'), value: 'onlyForOneEvent' },
  { label: i18n.t('aboDowngrade.form.option.tooExpensive'), value: 'tooExpensive' },
  { label: i18n.t('aboDowngrade.form.option.IdontNeedMinutes'), value: 'IdontNeedMinutes.' },
  { label: i18n.t('aboDowngrade.form.option.serviceBad'), value: 'serviceBad' },
  { label: i18n.t('aboDowngrade.form.option.tooManyTechProblems'), value: 'tooManyTechProblems' },
  { label: i18n.t('aboDowngrade.form.option.otherReason'), value: 'otherReason' }
]

export const AboDowngradePage = (props: AboDowngradePageProps) => {
  const [checkAgb, setCheckAgb] = useState<boolean>(false)
  const [callLoading, setCallLoading] = useState<boolean>(false)
  const [userOtions, setUserOtions] = useState<any>(() => {
    let userOtions = {}
    listOption.map((itm) => {
      userOtions = { ...userOtions, [itm.value]: false }
    })
    userOtions = { ...userOtions, textarea: '' }
    return userOtions
  })
  const history = useHistory()

  const handleUserOption = (value: string, option: boolean) => {
    userOtions[value] = option
    setUserOtions(userOtions)
  }

  const handleUserOptionText = (value: string) => {
    userOtions.textarea = value
    setUserOtions(userOtions)
  }

  const handleRemoveAbo = async () => {
    const contract = props.contractContainer.getCurrentContract()

    if (contract) {
      setCallLoading(true)
      await postContingentCancel(contract.RowKey)
      postContingentCancelReason(contract.RowKey, userOtions)
      await props.contractContainer.updateCurrentContract()
      history.push('/administration/subscriptionmanagement')
      setCallLoading(false)
    }
  }

  const renderBottomBar = () => {
    return (
      <div>
        <StaigeButton
          label={i18n.t('aboDowngrade.btnDowngradeSuccess')}
          style='white'
          iconMargin='0 -4px 0 4px'
          disabled={!checkAgb || callLoading}
          onClick={handleRemoveAbo}
        />
      </div>
    )
  }

  if (callLoading) {
    return (
      <PageWrapper
        headerTitle={i18n.t('aboDowngrade.backToTitle')}
        linkTo={`/administration/subscriptionmanagement`}
        simpleTopbar
        bottomBar={renderBottomBar}
      >
        <AiswSpinner />
      </PageWrapper>
    )
  }

  return (
    <PageWrapper
      headerTitle={i18n.t('aboDowngrade.backToTitle')}
      linkTo={`/administration/subscriptionmanagement`}
      simpleTopbar
      bottomBar={renderBottomBar}
    >
      <div className='page-aboUpgrade'>
        <div className='content-wrap'>
          <div className='wrap-content'>
            <div className='content-header'>
              <div className='header-title'>{i18n.t('aboDowngrade.title')}</div>
              <div className='header-subTitle'>
                {i18n.t('aboDowngrade.subTitle', {
                  userName: props.userContainer.state.user?.name || 'User'
                })}
              </div>
            </div>

            <div className='content-body'>
              <div className='body-form'>
                <div className='form-title'>{i18n.t('aboDowngrade.form.title')}</div>
                <div className='form-list'>
                  {listOption.map((itm, index) => {
                    return (
                      <StaigeCheckbox
                        key={index}
                        label={itm.label}
                        checked={userOtions[itm.label]}
                        handleClick={(option) => handleUserOption(itm.value, option)}
                      />
                    )
                  })}

                  <StaigeInput
                    placeholder={i18n.t('aboDowngrade.form.textareaPlaceholder')}
                    onChange={(event) => handleUserOptionText(event.target.value)}
                    textarea
                  />
                </div>
                <div className='form-agb'>
                  <StaigeCheckbox
                    handleClick={(value) => {
                      setCheckAgb(value)
                    }}
                    label={
                      <div>
                        {i18n.t('matchEditor.stepEvent.settings.paywall.agb1')}{' '}
                        <a className='agbBox-a' onClick={() => null}>
                          {i18n.t('matchEditor.stepEvent.settings.paywall.agb2')}
                        </a>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  )
}
