import React, { MouseEvent } from 'react'
import HeaderWrapper from '../../../helper/PageWrapper'
import i18n from 'i18next'
import AiswSpinner from '../../helper/aiswSpinner/aiswSpinner'
import iconArrow from '../../../img/icons/arrowTriangle.svg'
import iconUpLine from '../../../img/icons/upLine.svg'
import StaigeButton from '../../../components/styles/Button'
import StaigeBadge from '../../../components/styles/Badge'
import scheduleIcon from '../../../img/icons/schedule.svg'
import eyeIcon from '../../../img/icons/eye.svg'
import locatioOnIcon from '../../../img/icons/location_on.svg'
import localActivityIcon from '../../../img/icons/local_activity.svg'
import plusIcon from '../../../img/icons/plus.svg'

import { Link } from 'react-router-dom'
import { ContractContainer } from '../../../services/contractContainer'
import { VideoContainer } from '../../../services/videoContainerContracts'
import { Contract, VideoData, MonthReport, YearReport, getTimeReport } from '@soccerwatch/common'
import { /*postStripeFinish,*/ postContingentCreate, postContingentFinish } from '../../../api/backstaige_v2'

import './SubscriptionManagement.scss'

const isBeta = true

type UpgradeT = {
  style?: 'primary' | 'secondary' | 'danger-secondary' | 'ghost' | 'white'
  label?: string
  onClick?: () => void
  icon?: string
  iconMargin?: string
  disabled?: boolean
  linkTo?: string
}

type SubscriptionManagementPropTypes = {
  contractContainer: ContractContainer
  videoContainer: VideoContainer
  loadingContractData: boolean
}

type SubscriptionManagementStateTypes = {
  contractVideos?: VideoData[]
  timeReport?: ReturnType<typeof getTimeReport>
  currentYear?: YearReport
  currentMonth?: MonthReport
  loadingData: boolean
  openVideoList: {
    [title: string]: boolean
  }
  popup?: any
}

export default class SubscriptionManagement extends React.Component<
  SubscriptionManagementPropTypes,
  SubscriptionManagementStateTypes
> {
  constructor(props: SubscriptionManagementPropTypes) {
    super(props)
    this.state = {
      loadingData: true,
      openVideoList: {}
    }
  }

  componentDidMount(): void {
    this.getTimeReport()

    const channel = new BroadcastChannel('payment')
    channel.addEventListener('message', async (e) => {
      const { popup } = this.state
      const res = e.data

      if (res.success) {
        // success
        const contract = this.props.contractContainer.getCurrentContract()
        if (contract) {
          // await postStripeFinish(contract.RowKey, res.sessionId)
          await postContingentFinish(contract.RowKey, res.sessionId)
          this.setState({ loadingData: true })
          await this.props.contractContainer.updateCurrentContract()
          this.setState({ loadingData: false })
        }
      }

      if (popup && res.close) {
        popup.close()
      }
    })
  }

  getContractVideos = async (contract: Contract) => {
    const contractVideos = (
      (await this.props.videoContainer.getAllVideosOfContract(contract.RowKey)) as VideoData[]
    )
      //TODO: Check if Filterlogic isthis.getTimeReport() Valid
      .filter((game) => {
        return (
          game.error === undefined &&
          game.gpuerror === undefined &&
          (game.state !== 'done' || (game.state === 'done' && game.userStream))
        )
      })
    await this.setState({ contractVideos })
  }

  onDevClick = async (e: MouseEvent) => {
    if (e.ctrlKey) {
      await this.getTimeReport()
    }
  }

  getTimeReport = async () => {
    const { contracts, activeContract } = this.props.contractContainer.state
    const contract = contracts[activeContract]

    if (!this.state.contractVideos || !this.state.contractVideos.length) {
      await this.getContractVideos(contract)
    }
    if (!this.state.contractVideos) {
      console.error('Could not load ContractVideos')
      return
    }
    const videoDataCopy = [...this.state.contractVideos!]
    const timeReport = getTimeReport(videoDataCopy, contract.contingentAbos, new Date())
    const now = new Date()
    let currentYear,
      currentMonth = undefined
    currentYear = timeReport.mapsByYear[now.getFullYear()]
    if (currentYear) {
      currentMonth = currentYear.mapsByMonth[now.getMonth()] as MonthReport
    }
    this.setState({ timeReport, currentYear, currentMonth, loadingData: false })
  }

  minutesWithoutFullHours(minutes = 0) {
    return minutes % 60
  }

  groupArrayByDay(arr: VideoData[]) {
    if (!Array.isArray(arr) || arr.length === 0) {
      return []
    }
    const groupedByDay: any = {}
    arr.forEach((item) => {
      const date = new Date(item.expectedStartTime)
      const dayKey = date.toISOString().split('T')[0]

      if (!groupedByDay[dayKey]) {
        groupedByDay[dayKey] = []
      }

      groupedByDay[dayKey].push(item)
    })

    const result = Object.keys(groupedByDay).map((dayKey) => ({
      day: dayKey,
      items: groupedByDay[dayKey]
    }))

    return result
  }

  groupArrayByFutureAndPast(arr: Array<{ day: string; items: VideoData[] }>) {
    if (!Array.isArray(arr) || arr.length === 0) {
      return { future: [], past: [] }
    }
    const now = new Date().getTime()
    const grouped: {
      future: { day: string; items: VideoData[] }[]
      past: { day: string; items: VideoData[] }[]
    } = {
      future: [],
      past: []
    }

    arr.forEach((item) => {
      const timestamp = new Date(item.day).getTime()
      if (timestamp > now) {
        grouped.future.push(item)
      } else {
        grouped.past.push(item)
      }
    })

    return grouped
  }

  formatDateToCustomString(date: Date) {
    const daysOfWeek = [
      i18n.t('general.day.su'),
      i18n.t('general.day.mo'),
      i18n.t('general.day.tu'),
      i18n.t('general.day.we'),
      i18n.t('general.day.th'),
      i18n.t('general.day.fr'),
      i18n.t('general.day.sa')
    ]
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
    const day = daysOfWeek[date.getDay()]
    const dayOfMonth = date.getDate().toString().padStart(2, '0')
    const month = months[date.getMonth()]
    const year = date.getFullYear()
    return `${day}, ${dayOfMonth}.${month}.${year}`
  }

  renderInfoTextBox(icon: string, text: string) {
    return (
      <div className='box-textBox'>
        <div className='textBox-icon'>
          <img src={icon} />
        </div>
        <div className='textBox-text'>{text}</div>
      </div>
    )
  }

  renderVideoBox = (videos: { day: string; items: VideoData[] }, index: number) => {
    const day = this.formatDateToCustomString(new Date(videos.day))

    return (
      <div className='videoList-day' key={index}>
        <div className='day-date'>{day}</div>
        {videos.items.map((video, index) => {
          const expectedStartResult = String(video.durationMin)

          return (
            <Link to={'/recordingplan/video/' + video.RowKey} key={index} className='videoList-videoBox'>
              <div className='videobox-info'>
                <div className='info-top'>
                  <div className='top-badges'>
                    <StaigeBadge
                      active={video.videoProcessing === 'soccer' && video.contentType === 'broadcast'}
                      title={i18n.t('administration.contractStramingAbo.aboBox.badges.match')}
                      color='violet'
                    />
                    <StaigeBadge
                      active={video.videoProcessing === 'soccer' && video.contentType === 'training'}
                      title={i18n.t('administration.contractStramingAbo.aboBox.badges.training')}
                      color='white'
                    />
                    <StaigeBadge active={video.videoProcessing === 'soccer'} title={video.teamAAge} />
                  </div>
                  {this.renderInfoTextBox(scheduleIcon, expectedStartResult)}
                  {this.renderInfoTextBox(
                    eyeIcon,
                    video.privateActive ? i18n.t('general.private') : i18n.t('general.public')
                  )}
                  {this.renderInfoTextBox(
                    locatioOnIcon,
                    video.address || video.field || video.city || i18n.t('recording.unknown')
                  )}
                  {this.renderInfoTextBox(
                    localActivityIcon,
                    video.paywall ? i18n.t('recording.paywall') : i18n.t('recording.free')
                  )}
                </div>
                <div className='into-bottom'>
                  <div className='bottom-team'>
                    <div className='team-logo'>
                      <img
                        src={
                          'https://storage.googleapis.com/sw-sc-de-assets/clubLogo/club-' +
                          video.clubAId +
                          '.png'
                        }
                      />
                    </div>
                    <div className='team-name'>{video.clubAName}</div>
                  </div>

                  {video.scoreA && video.scoreA >= 0 && video.scoreB && video.scoreB >= 0 ? (
                    <div className='bottom-vs'>
                      {video.scoreA}-{video.scoreB}
                    </div>
                  ) : (
                    <div className='bottom-vs'>VS</div>
                  )}

                  <div className='bottom-team'>
                    <div className='team-logo'>
                      <img
                        src={
                          'https://storage.googleapis.com/sw-sc-de-assets/clubLogo/club-' +
                          video.clubBId +
                          '.png'
                        }
                      />
                    </div>
                    <div className='team-name'>{video.clubBName}</div>
                  </div>
                </div>
              </div>
              <div className='videobox-icon'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <mask id='mask0_693_13796' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'>
                    <rect width='24' height='24' fill='#F6F6F6' />
                  </mask>
                  <g mask='url(#mask0_693_13796)'>
                    <path d='M15 19L13.6 17.6L18.2 13H2V11H18.2L13.6 6.4L15 5L22 12L15 19Z' fill='#F6F6F6' />
                  </g>
                </svg>
              </div>
            </Link>
          )
        })}
      </div>
    )
  }

  handleOpenVideoList = (title: string) => {
    let { openVideoList } = this.state
    if (openVideoList[title]) {
      openVideoList[title] = !openVideoList[title]
    } else {
      openVideoList = { ...openVideoList, [title]: true }
    }
    this.setState({
      openVideoList
    })
  }

  openStripeUpgrade = async () => {
    const currentContract = this.props.contractContainer.getCurrentContract()?.RowKey

    if (currentContract) {
      const stripeLink = await postContingentCreate(currentContract, isBeta)
      const popup = window.open(
        stripeLink,
        '',
        'toolbar=no, menubar=no, width=600, height=700, top=100, left=100'
      )
      popup?.focus()
      this.setState({
        popup: popup
      })
    }
  }

  openStripeDowngrade = async () => {
    // TODO
    // const stripeLink = await getStripeUpgradeLink()
    // const popup = window.open(stripeLink.url, '', 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100')
    // popup?.focus()
    /*this.setState({
      popup: popup
    })*/
  }

  renderAboBox = (
    active: boolean,
    title: string,
    infoText: string,
    infoText1: string,
    aboType: string,
    timeAvailable: number,
    timeUsed: number,
    videos: VideoData[],
    upgrade?: UpgradeT
  ) => {
    if (!active) {
      return
    }
    videos = videos.sort((a, b) => b.expectedStartTime - a.expectedStartTime)
    const videosByDays = this.groupArrayByDay(videos)
    const videosByFutureAndPast = this.groupArrayByFutureAndPast(videosByDays)

    return (
      <div className='main-aboBox'>
        <div className='aboBox-main'>
          <div className='main-header'>
            <div className='header-left'>
              {i18n.t('administration.contractStramingAbo.aboBox.headerTitleLeft')}
            </div>
            <div className='header-right'>{aboType}</div>
          </div>
          <div className='main-info'>
            {title.length > 0 ? (
              <div className='info-title'>{title}</div>
            ) : (
              <div className='info-title'>{i18n.t('administration.contractStramingAbo.aboBox.abo')}</div>
            )}
            <div className='info-infoText'>{infoText}</div>
            <div className='info-infoText'>{infoText1}</div>
          </div>
          <div className='main-counter'>
            <div className='counter-infoBar'>
              <div className='infoBar-title'>
                {i18n.t('administration.contractStramingAbo.aboBox.infoBarTitle')}
              </div>
              <div className='infoBar-timer'>
                <div className='timer-a'>{timeUsed}</div>
                <div className='timer-b'>/</div>
                <div className='timer-b'>{timeAvailable}</div>
                <div className='timer-b'>{i18n.t('administration.contractStramingAbo.infoBox.min')}</div>
              </div>
            </div>
            <div className='counter-bar'>
              <div className='bar' style={{ width: (timeUsed * 100) / timeAvailable + '%' }} />
            </div>
          </div>
          {/*upgrade !== undefined && upgrade.disabled === false ? (
            <div className='main-quickBtn'>
              {upgrade?.label === i18n.t('administration.contractStramingAbo.aboBox.downgradeBtn') && (
                <StaigeButton
                  style={'ghost'}
                  label={'Abo Übersicht'}
                  onClick={() => {
                    const popup = window.open(
                      isBeta
                        ? 'https://billing.stripe.com/p/login/test_fZe4gJdLl1mL57qaEE'
                        : 'https://billing.stripe.com/p/login/00g9De1zYblpa64bII',
                      '',
                      'toolbar=no, menubar=no, width=600, height=700, top=100, left=100'
                    )
                    popup?.focus()
                  }}
                />
              )}

              <StaigeButton
                style={upgrade?.style}
                label={upgrade?.label}
                icon={upgrade?.icon}
                iconMargin={upgrade?.iconMargin}
                disabled={upgrade?.disabled}
                onClick={upgrade?.onClick}
                linkTo={upgrade?.linkTo}
              />
            </div>
          ) : null*/}
        </div>
        <div className='aboBox-trigger' onClick={() => this.handleOpenVideoList(title)}>
          <div className='trigger-title'>
            {i18n.t('administration.contractStramingAbo.aboBox.triggerTitle')}
          </div>
          <div className='trigger-icon'>
            {this.state.openVideoList[title] ? (
              <span className='mbsc-ic mbsc-ic-fa-angle-up' />
            ) : (
              <span className='mbsc-ic mbsc-ic-fa-angle-down' />
            )}
          </div>
        </div>

        {!this.state.openVideoList[title] || (
          <div className='aboBox-videoList'>
            <div className='videoList-header'>
              <div className='header-left'>
                {i18n.t('administration.contractStramingAbo.aboBox.upcomingRecordings')}
              </div>
              <div className='header-right'>
                <StaigeButton
                  style='ghost'
                  size='small'
                  label={i18n.t('administration.contractStramingAbo.aboBox.planRecording')}
                  icon={plusIcon}
                  linkTo='/recordingplan/new'
                />
              </div>
            </div>
            {videosByFutureAndPast.future.map((future, index) => {
              return this.renderVideoBox(future, index)
            })}

            <div className='videoList-header'>
              <div className='header-left'>
                {i18n.t('administration.contractStramingAbo.aboBox.pastRecordings')}
              </div>
            </div>
            {videosByFutureAndPast.past.map((past, index) => {
              return this.renderVideoBox(past, index)
            })}
          </div>
        )}
      </div>
    )
  }

  renderHelpContainer = () => {
    return (
      <a href='mailto:clubsupport@aisportswatch.com'>
        <div className='second-helpContainer'>
          <div className='helpContainer-headerInfo'>
            <div className='headerInfo-text'>
              {i18n.t('administration.contractStramingAbo.helpContainer.title')}
            </div>
            <div className='headerInfo-icon'>
              <img src={iconArrow} />
            </div>
          </div>
          <div className='helpContainer-textInfo'>
            {i18n.t('administration.contractStramingAbo.helpContainer.infoText')}
          </div>
        </div>
      </a>
    )
  }

  renderOfferContainer = () => {
    return (
      <div className='offer-container'>
        <div className='container-headerInfo'>
          <div className='headerInfo-title'>Upgrade</div>
          <div className='headerInfo-subTitle'>1.300 Min. / Monat</div>
          <div className='headerInfo-icon'>
            <span className='mbsc-ic mbsc-icon-material-info-outline' />
          </div>
        </div>
        <div className='container-info'>
          <div className='info-title'>LTE Flat Paket</div>
          <div className='info-text'>
            Dein Verein hat mehr zu zeigen als vier Spiele? Für nur 69 EUR sicherst du dir mit unserer
            LTE-Flat zehn Live-Übertragungen pro Monat.
          </div>
        </div>
        <div className='container-btn'>
          <StaigeButton style='secondary' label={'Upgrade'} fullWidth />
        </div>
      </div>
    )
  }

  render() {
    const { currentMonth, currentYear, timeReport } = this.state
    const { contracts, activeContract, loadingData } = this.props.contractContainer.state
    const contract = contracts[activeContract]

    if (contract === undefined || loadingData || this.state.loadingData) {
      return (
        <HeaderWrapper
          headerTitle={i18n.t('administration.contractStramingAbo.menuTitle')}
          linkTo='/administration'
          style={{ height: '100%' }}
        >
          <AiswSpinner />
        </HeaderWrapper>
      )
    }

    let pack = 'flex'
    let upgrade: UpgradeT | undefined = {
      style: 'secondary',
      label: i18n.t('administration.contractStramingAbo.aboBox.upgradeBtn'),
      onClick: this.openStripeUpgrade,
      icon: iconUpLine,
      iconMargin: '0 0 0 -7px',
      disabled: true,
      linkTo: undefined
    }

    contract.contingentAbos.map((contigent) => {
      switch (contigent.transactionId) {
        case 'Standard 520M':
          pack = 'standardLTE'
          upgrade = {
            ...upgrade,
            disabled: false
          }
          break
        case 'Pro LTE 1300M':
          const now = new Date().getTime()
          const contigentTime = new Date(contigent.validTo).getTime()
          //@ts-ignore
          if (contigent.canceled && now > contigentTime) {
            pack = 'standardLTE'
            upgrade = {
              ...upgrade,
              disabled: false
            }
            break
            //@ts-ignore
          } else if (contigent.canceled && now < contigentTime) {
            pack = 'proLTE'
            upgrade = {
              ...upgrade,
              disabled: true
            }
            break
          }

          pack = 'proLTE'
          upgrade = {
            style: 'danger-secondary',
            label: i18n.t('administration.contractStramingAbo.aboBox.downgradeBtn'),
            onClick: this.openStripeDowngrade,
            icon: '',
            iconMargin: '',
            disabled: false,
            linkTo: '/administration/subscriptionmanagement/downgrade'
          }

          break
        case 'Pro LAN 1300M':
          pack = 'lan'
          upgrade = undefined
          break
        default:
          upgrade = undefined
          break
      }
    })

    return (
      <HeaderWrapper
        headerTitle={i18n.t('administration.contractStramingAbo.menuTitle')}
        linkTo='/administration'
      >
        <div className='subscriptionManagement'>
          <div className='content-wrap'>
            <div className='wrap-main'>
              {this.renderAboBox(
                Boolean(currentMonth?.singleUseContingent.timeAvailable),
                i18n.t(`administration.contractStramingAbo.aboBox.${pack}.title`),
                i18n.t(`administration.contractStramingAbo.aboBox.${pack}.info`),
                i18n.t(`administration.contractStramingAbo.aboBox.${pack}.info1`),
                i18n.t('administration.contractStramingAbo.aboBox.monthly.aboType'),
                currentMonth?.singleUseContingent.timeAvailable ?? 0,
                currentMonth?.singleUseContingent.timeUsed ?? 0,
                currentMonth?.singleUseContingent.covered || [],
                upgrade
              )}

              {this.renderAboBox(
                Boolean(currentMonth?.timeAvailable),
                '',
                '',
                '',
                i18n.t('administration.contractStramingAbo.aboBox.monthly.aboType'),
                currentMonth?.timeAvailable ?? 0,
                currentMonth?.timeUsed ?? 0,
                currentMonth?.covered || []
              )}

              {this.renderAboBox(
                Boolean(currentYear?.result.timeAvailable),
                '',
                '',
                '',
                i18n.t('administration.contractStramingAbo.aboBox.yearly.aboType'),
                currentYear?.result.timeAvailable ?? 0,
                currentYear?.result.timeUsed ?? 0,
                currentYear?.result.covered || []
              )}

              {this.renderAboBox(
                Boolean(timeReport?.result.timeAvailable),
                '',
                '',
                '',
                i18n.t('administration.contractStramingAbo.aboBox.free.aboType'),
                timeReport?.result.timeAvailable ?? 0,
                timeReport?.result.timeUsed ?? 0,
                timeReport?.result.covered || []
              )}
            </div>
            <div className='wrap-second'>{this.renderHelpContainer()}</div>
          </div>
        </div>
      </HeaderWrapper>
    )
  }
}
