import React from 'react'
import PropTypes, { InferProps } from 'prop-types'
import ContractContainer from '../services/contractContainer'

import './HeaderWrapper.scss'

const headerWrapperPropTypes = {
  backLink: PropTypes.string,
  headerTitle: PropTypes.string,
  className: PropTypes.string,
  burgerMenuRightOnClick: PropTypes.func,
  btnRight: PropTypes.func,
  btnRightIcon: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  openMenu: PropTypes.bool,
  extraMenu: PropTypes.node,
  contractDisabled: PropTypes.bool
}

export type HeaderWrapperProps = InferProps<typeof headerWrapperPropTypes> & { lockContractSelect?: boolean }

type HeaderWrapperState = {
  renderFlip: boolean
  visibleScrollbar: boolean
  widthScrollbar: number
}

export default class ContentHeader extends React.Component<HeaderWrapperProps, HeaderWrapperState> {
  static propTypes = headerWrapperPropTypes

  constructor(props: HeaderWrapperProps) {
    super(props)
    this.state = {
      renderFlip: false,
      visibleScrollbar: false,
      widthScrollbar: 0
    }
  }

  componentDidMount() {
    let div = document.getElementById('scrollbarCheck')

    if (div !== null) {
      let hasVerticalScrollbar = div.scrollHeight > div.clientHeight
      this.setState({
        visibleScrollbar: hasVerticalScrollbar,
        widthScrollbar: div.offsetWidth - div.clientWidth
      })
    }
  }

  updateDimensions = () => {
    let div = document.getElementById('scrollbarCheck')

    if (div !== null) {
      let hasVerticalScrollbar = div.scrollHeight > div.clientHeight
      this.setState({
        visibleScrollbar: hasVerticalScrollbar,
        widthScrollbar: div.offsetWidth - div.clientWidth
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  componentDidUpdate() {
    window.addEventListener('resize', this.updateDimensions)

    let div = document.getElementById('scrollbarCheck')

    if (div !== null) {
      let hasVerticalScrollbar = div.scrollHeight > div.clientHeight
      if (this.state.visibleScrollbar !== hasVerticalScrollbar) {
        this.setState({
          visibleScrollbar: hasVerticalScrollbar,
          widthScrollbar: div.offsetWidth - div.clientWidth
        })
      }
    }
  }

  render() {
    let checkScroll = false
    if (this.state.visibleScrollbar) {
      if (this.state.widthScrollbar > 0) {
        checkScroll = true
      }
    }

    const mbscGridBase =
      ContractContainer.state.contracts.length > 1
        ? 'mbsc-grid-base-contract'
        : 'mbsc-grid-base-withoutContract'
    const bgColor = this.props.color === 'secondary'
    const genBackgroudLines = (
      <div className='s-grid-lines' style={checkScroll ? { padding: '0 7px' } : {}}>
        <div className={!this.props.openMenu ? 's-grid-line' : 's-grid-line-second'} />
        <div className={!this.props.openMenu ? 's-grid-line' : 's-grid-line-second'} />
      </div>
    )

    return (
      <>
        {this.props.extraMenu ? (
          <div className={'app-header'}>
            <div className={'header-container'}>
              {this.props.extraMenu ? <div>{this.props.extraMenu}</div> : null}
            </div>
          </div>
        ) : null}

        <div style={{}}>
          <div
            className={`mbsc-grid mbsc-grid-base ${mbscGridBase}`}
            style={bgColor ? { height: '100%' } : { backgroundColor: '#121212', height: '100%' }}
          >
            <div
              className='mbsc-row mbsc-justify-content-center'
              style={{ height: '100%' }}
              id={'scrollbarCheck'}
            >
              <div
                className={`${this.props.className} mbsc-col-sm-12 mbsc-col-md-10  mbsc-col-xl-8`}
                style={{ position: 'absolute', height: '100%' }}
              >
                {genBackgroudLines}
              </div>

              <div
                className={`${this.props.className} mbsc-col-sm-12 mbsc-col-md-10  mbsc-col-xl-8`}
                style={{ height: 'calc(100% - 88px)', minHeight: '-webkit-fill-available' }}
              >
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
