import React from 'react'
import mobiscroll from '@mobiscroll/react'
import iconStar from '../../../img/icons/IconStars.svg'

import './overlay.scss'
import StaigeSwitch from '../../../components/styles/Switch'
import StaigeButton from '../../../components/styles/Button'

type OverlayPropTypes = {
  text: string
  iconSrc?: string
  infoText?: string
  btnConfig?: Array<{ title: string; click?: any }>
  activeTimerSec?: number
  className?: string
  popupDisable?: boolean
  deactivatedHelper?: () => void
}

type OverlayState = {
  active: boolean
  activeTimer: number
}

export default class Overlay extends React.Component<OverlayPropTypes, OverlayState> {
  constructor(props: OverlayPropTypes) {
    super(props)
    this.state = {
      active: false,
      activeTimer: this.props.activeTimerSec !== undefined ? this.props.activeTimerSec * 1000 : 3000
    }
  }

  componentDidUpdate(prevProps: Readonly<OverlayPropTypes>, prevState: Readonly<OverlayState>): void {
    if (prevState.active !== this.state.active && this.state.active && this.props.btnConfig?.length === 0) {
      setTimeout(() => {
        this.closeOverlay()
      }, this.state.activeTimer)
    }
  }

  closeOverlay = () => {
    this.setState({
      active: false
    })
  }

  showOverlay = () => {
    this.setState({
      active: true
    })
  }

  handleClick = async (click?: () => void | any) => {
    if (click !== undefined) {
      await click()
    }

    // close overlay
    this.closeOverlay()
  }

  render() {
    if (!this.state.active && !this.props.popupDisable) return null

    return (
      <div className={'tag-overlayBox ' + this.props.className}>
        {this.props.deactivatedHelper ? (
          <div className='deactivated'>
            <StaigeButton
              label='deaktiveren'
              style='ghost'
              size='small'
              onClick={this.props.deactivatedHelper}
            />
          </div>
        ) : null}

        <div className='icon-container'>
          <img src={this.props.iconSrc ? this.props.iconSrc : iconStar} />
        </div>
        <div className='overlay-content'>
          <div className='content-text'>{this.props.text}</div>
          {this.props.infoText ? <div className='content-infoText'>{this.props.infoText}</div> : null}
        </div>
        {this.props.btnConfig && this.props.btnConfig.length > 0 ? (
          <div className='overlay-btn'>
            {this.props.btnConfig.map((btn, index) => {
              return (
                <mobiscroll.Button
                  key={index}
                  onClick={() => this.handleClick(btn.click)}
                  className={'btn'}
                  outline
                  color='primary'
                  style={this.props.btnConfig?.length === 1 ? { width: '100%' } : {}}
                >
                  {btn.title || 'title'}
                </mobiscroll.Button>
              )
            })}
          </div>
        ) : null}
      </div>
    )
  }
}
