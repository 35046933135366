import React from 'react'

import './Tag.scss'

type staigeTagPropTypes = {
  label: string | undefined
  active?: boolean
  color?: 'white' | 'violet' | 'blue' | 'red'
  onClick?: () => void
  disabled?: boolean
}

export default class StaigeTag extends React.Component<staigeTagPropTypes, unknown> {
  render() {
    const { label, active, disabled } = this.props
    return (
      <div
        className={`staige-tag ${active ? 'staige-tagActive' : ''}`}
        onClick={!disabled ? this.props.onClick : () => {}}
      >
        <div className={`tag-lable`}>{label ?? 'lable fehlt'}</div>
      </div>
    )
  }
}
