import React from 'react'

import contractContainer, { ContractContainer } from '../../services/contractContainer'
import { Route, Switch, useRouteMatch, useHistory, Redirect } from 'react-router-dom'
import { EditAdvertisement } from '../sponsors/EditSponsors'
import { Sponsors } from '../sponsors/Sponsors'
import { AdvertisementContainer } from '../../services/advertisementContainer'
import { CameraContainer } from '../../services/cameraContainer'
import { Role } from '@soccerwatch/common'
import { RouteNames } from '../menu/Menu'
import { ClubContainer } from '../../services/clubContainer'

export type AdManagementRoutesProps = {
  advertisementContainer: AdvertisementContainer
  contractContainer: ContractContainer
  cameraContainer: CameraContainer
  routeFilter: string[]
  reroutePath: string
  clubContainer: ClubContainer
}
// This will be wrapped in AdministrationContext before export!
const AdManagementRoutes = (props: AdManagementRoutesProps) => {
  if (props.routeFilter && !props.routeFilter.includes(RouteNames.Advertisement)) {
    return <Redirect to={props.reroutePath} />
  }
  const match = useRouteMatch()
  const history = useHistory()
  if (
    !props.cameraContainer.state.loadingData &&
    Object.keys(props.cameraContainer.state.cameras).length <= 0 &&
    contractContainer.getHighestRelevantRoleForCurrentContract() !== Role.clubTagger
  ) {
    history.replace(`/claim/camera`)
    return null
  }

  return (
    <Switch>
      <Route path={`${match.path}/`} exact>
        <Sponsors
          advertisementContainer={props.advertisementContainer}
          adMaterials={props.advertisementContainer.getAdMaterialsForCurrentContract()}
          contractChanging={props.contractContainer.state.loadingData}
        />
      </Route>
      <Route path={`${match.path}/new/:adType`} exact>
        <EditAdvertisement
          advertisementContainer={props.advertisementContainer}
          containerLoading={
            props.advertisementContainer.state.loadingData || props.contractContainer.state.loadingData
          }
          new
          contractContainer={props.contractContainer}
          clubContainer={props.clubContainer}
        />
      </Route>
      <Route path={`${match.path}/edit/:adID`} exact>
        <EditAdvertisement
          advertisementContainer={props.advertisementContainer}
          containerLoading={
            props.advertisementContainer.state.loadingData || props.contractContainer.state.loadingData
          }
          contractContainer={props.contractContainer}
          clubContainer={props.clubContainer}
        />
      </Route>
    </Switch>
  )
}

export default AdManagementRoutes
