import { VideoData } from '@soccerwatch/common'

// These states are computed from the match's `status` and `active` properties
// and the information whether the match has a video or not. For more details,
// please refer to the corresponding trello ticket:
// https://trello.com/c/qUrQUN59/2749-l%C3%B6schen-button-in-einen-deaktivieren-button-umwandeln
export enum RecordingStatus {
  UNKNOWN,
  CREATED_MANUALLY_OR_CONFIRMED,
  IMPORTED,
  CANCELLED_BUT_CAN_BE_REACTIVATED,
  CANCELLED_AND_CAN_NOT_BE_REACTIVATED,
  RECORDED_AND_ACTIVE,
  RECORDED_BUT_INACTIVE
}

const hasVideo = (video: VideoData): boolean =>
  Boolean(
    video.vrStream ||
      video.userStream ||
      video.gridStream ||
      video.userStreamAdaptive ||
      video.view_userStream ||
      video.view_gridStream
  )

// Please see the trello ticket linked above for explanation to the states
export const getRecordingStatus = (video: VideoData): RecordingStatus => {
  if (video.state === 'created') {
    if (video.active) {
      return RecordingStatus.CREATED_MANUALLY_OR_CONFIRMED
    } else {
      return RecordingStatus.IMPORTED
    }
  } else if (video.state === 'done') {
    if (video.active) {
      return RecordingStatus.RECORDED_AND_ACTIVE
    } else if (hasVideo(video)) {
      return RecordingStatus.RECORDED_BUT_INACTIVE
    } else {
      const now = new Date()
      const fiveMinutesInMilliseconds = 5 * 60 * 1000
      const lastActivateableStartingTime = new Date(now.getTime() + fiveMinutesInMilliseconds)
      const videoCanStillBeActivated = new Date(video.startTime) > lastActivateableStartingTime

      if (videoCanStillBeActivated) {
        return RecordingStatus.CANCELLED_BUT_CAN_BE_REACTIVATED
      } else {
        return RecordingStatus.CANCELLED_AND_CAN_NOT_BE_REACTIVATED
      }
    }
  } else {
    return RecordingStatus.UNKNOWN
  }
}
