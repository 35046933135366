import { Container } from 'unstated-typescript'
import DiscoveryType from '@soccerwatch/discovery'
import Discovery from './discovery'
import * as Helper from '../helper/GlobalHelper'

type IceServer = {
  urls: string | string[]
  username?: string
  credential?: string
}

type ServerMessageData = {
  sdp?: RTCSessionDescriptionInit
  ice?: RTCIceCandidateInit
}

export enum ClosedDueStates {
  'TIMEOUT_OR_ERROR' = 'TIMEOUT_OR_ERROR',
  'TIMEOUT' = 'TIMEOUT',
  'ERROR' = 'ERROR',
  'CAMERA_NOT_REACHABLE' = 'CAMERA_NOT_REACHABLE',
  'CAMERA_IN_USE' = 'CAMERA_IN_USE'
}

export type WebRTCState = {
  bitrate: number
  useVideoTime: boolean
  discovery?: typeof DiscoveryType
  currentCam: string
  currentStream?: MediaStream
  peerId?: string
  wsUrl?: string
  wsPort?: string
  wsServer?: string
  wsCon?: WebSocket
  streamStarted: boolean
  connectAttempts: number
  currentSensor: string
  sensorSwitchingAt: number
  peerConnection?: RTCPeerConnection
  sendChannel?: RTCDataChannel
  connectionClosedDue?: ClosedDueStates
  currentlyRestarting: boolean
  rtcConfig: {
    iceServers: IceServer[]
  }
}

export class WebRTCContainer extends Container<WebRTCState> {
  discovery?: typeof DiscoveryType

  constructor() {
    super()

    this.state = {
      bitrate: 1000,
      useVideoTime: false,
      currentCam: '-1',
      currentStream: undefined,
      peerId: undefined,
      wsUrl: undefined,
      wsPort: undefined,
      wsServer: 'webrtc-dot-aisw-ww-prod.ew.r.appspot.com',
      streamStarted: false,
      connectAttempts: 0,
      peerConnection: undefined,
      currentSensor: '0',
      sensorSwitchingAt: -1,
      currentlyRestarting: false,
      rtcConfig: {
        iceServers: [
          { urls: 'stun:stun.services.mozilla.com' },
          { urls: 'stun:stun.l.google.com:19302' },
          {
            username:
              'x5noDe6NY0NT6fd9yADlCIO-SuYmsL4J8zNGt9Dxe3JBUOo6NQODOxYE6jEaKOl2AAAAAGDddRljYm9kZW5zdA==',
            credential: 'cc1b443e-da41-11eb-a55a-0242ac140004',
            urls: ['turns:eu-turn3.xirsys.com:443?transport=tcp']
          }
        ]
      }
    }
    Discovery.then((d) => {
      this.setState({ discovery: d })
    })
  }

  startStream = async (camera: string) => {
    if (this.state.peerId && this.state.wsUrl) {
      console.log('Starting Stream with Bitrate', this.state.bitrate)
      await Discovery

      if (!this.state.discovery) {
        throw new Error('Discovery not found while starting stream!')
      }

      const url = this.state.discovery.API_CAMERA + '/control/' + camera + '/start'
      const data = {
        bitrate: this.state.bitrate,
        protocol: 'webrtc',
        serviceAccount: { server: this.state.wsUrl, peer_id: this.state.peerId }
      }
      const result = await Helper.apiPost<{
        detail?: {
          state?: string
        }
        swcsReached: unknown
      }>(url, data)
      if (!result.swcsReached) {
        // TODO: Change Alerts to Mobiscroll.alert
        await this.setState({ connectionClosedDue: ClosedDueStates.CAMERA_NOT_REACHABLE })
        return
      }
      if (result?.detail?.state == 'video_device_already_in_use') {
        await this.setState({ connectionClosedDue: ClosedDueStates.CAMERA_IN_USE })
        return
      }
      await this.setState({ currentCam: camera, streamStarted: true })
    } else {
      console.error(this.state.peerId, this.state.wsUrl)
      console.error('PeerId not found please reload')
      this.onServerClose()
    }
  }

  websocketServerConnect = () => {
    const connectAttempts = this.state.connectAttempts + 1
    this.setState({ connectAttempts })

    if (connectAttempts > 3) {
      console.error('Too many connection attempts, aborting. Refresh page to try again')
      return
    }
    // Fetch the peer id to use
    let wsPort = this.state.wsPort || '443'
    let wsServer
    if (window.location.protocol.startsWith('file')) {
      wsServer = this.state.wsServer || '127.0.0.1'
    } else if (window.location.protocol.startsWith('http')) {
      wsServer = this.state.wsServer || window.location.hostname
    } else {
      throw new Error("Don't know how to connect to the signalling server with uri" + window.location)
    }
    let prefix = 'wss://'
    if (wsServer == 'localhost') {
      prefix = 'ws://'
      wsPort = '8080'
    }
    const wsUrl = prefix + wsServer + ':' + wsPort + '/webrtc'
    console.log('Connecting to server ' + wsUrl)
    const wsCon = new WebSocket(wsUrl)
    /* When connected, immediately register with the server */
    wsCon.addEventListener('open', () => {
      wsCon.send('HELLO')
      console.log('Registering with server')
    })
    wsCon.addEventListener('error', this.onServerError)
    wsCon.addEventListener('message', this.onServerMessage)
    wsCon.addEventListener('close', () => {
      this.onServerClose()
    })
    this.setState({ wsCon, wsPort, wsServer, wsUrl })
  }

  onServerError = () => {
    console.error('Unable to connect to server, did you add an exception for the certificate?')
    // Retry after 3 seconds
    window.setTimeout(this.websocketServerConnect, 3000)
  }

  onServerClose = (error?: Error) => {
    if (error) {
      this.resetConnectionsAndState(ClosedDueStates.ERROR)
    } else {
      this.resetConnectionsAndState(ClosedDueStates.TIMEOUT_OR_ERROR)
    }
    // Reset after a second
    window.setTimeout(this.websocketServerConnect, 1000)
  }

  resetConnectionsAndState = (closedReason: ClosedDueStates = ClosedDueStates.TIMEOUT_OR_ERROR) => {
    if (!this.state.currentlyRestarting) {
      this.setState({ currentlyRestarting: true, connectionClosedDue: closedReason }, () => {
        console.log('clean up rtc', this.state)
        if (this.state.peerConnection) {
          this.state.peerConnection.close()
        }
        if (this.state.wsCon) {
          this.state.wsCon.close()
        }
        // Reset States also sets currentlyRestarting = false
        this.resetState()
      })
    }
  }

  resetState = () => {
    this.setState({
      peerConnection: undefined,
      wsCon: undefined,
      currentStream: undefined,
      peerId: undefined,
      wsUrl: undefined,
      wsPort: undefined,
      wsServer: 'webrtc-dot-aisw-ww-prod.ew.r.appspot.com',
      sendChannel: undefined,
      streamStarted: false,
      connectAttempts: 0,
      currentSensor: '0',
      bitrate: 1000,
      currentCam: '-1',
      sensorSwitchingAt: -1,
      connectionClosedDue: undefined,
      currentlyRestarting: false
    })
  }

  handleHelloMessage = (event: MessageEvent) => {
    console.log('Registered with server, waiting for call')
    const peerId = event.data.replace('HELLO ', '')
    console.log('Peer id: ' + peerId)
    this.setState({ peerId })
  }

  tryParseMsgData = (data: string) => {
    if (data.includes('SENSORSWITCH')) {
      return data
    }
    if (data.includes('CLOSE')) {
      return data
    }
    try {
      return JSON.parse(data)
    } catch (e) {
      if (e instanceof SyntaxError) {
        this.handleIncomingError('Error parsing incoming JSON: ' + data)
      } else {
        this.handleIncomingError('Unknown error parsing response: ' + data)
      }
      return //Registered
    }
  }

  onServerMessage = (event: MessageEvent) => {
    if (event.data.startsWith('HELLO')) {
      this.handleHelloMessage(event)
    } else if (event.data.startsWith('ERROR')) {
      if (event.data.includes('Session closed')) {
        console.log('Session Closed')
        this.onServerClose(new Error('Server Connection Closed'))
        return
      } else {
        console.error('Error on WebRTC:', event.data)
        this.handleIncomingError(event.data)
        return
      }
    } else if (event.data.startsWith('SENSORSWITCH')) {
      this.handleSensorSwitch(event.data)
      return
    } else if (event.data.startsWith('CLOSE')) {
      this.onServerClose()
      return
    } else {
      // Handle incoming JSON SDP and ICE messages
      const msg: ServerMessageData = this.tryParseMsgData(event.data)
      if (!msg) {
        return
      }

      // Incoming JSON signals the beginning of a call
      if (!this.state.peerConnection) {
        this.createCall(msg)
      }

      if (msg.sdp != null) {
        this.onIncomingSDP(msg.sdp)
      } else if (msg.ice != null) {
        this.onIncomingICE(msg.ice)
      } else {
        this.handleIncomingError('Unknown incoming JSON: ' + msg)
      }
    }
  }

  handleSensorSwitch = (msg: string) => {
    const parts = msg.split(' ')
    console.log(msg)
    try {
      let sensorSwitchingAt = parseInt(parts[2], 10)
      if (this.state.useVideoTime) {
        sensorSwitchingAt = parseInt(parts[3], 10)
        sensorSwitchingAt = sensorSwitchingAt / 1000000000
      }
      this.setState({ sensorSwitchingAt })
    } catch (err) {
      console.error('HandleSensorSwitch: Unexpected format in Msg:', msg)
    }
  }

  sensorSwitched = () => {
    this.setState({ sensorSwitchingAt: -1 })
  }

  handleIncomingError = (error: string) => {
    console.error('ERROR: ' + error)
    this.onServerClose(new Error(error))
  }

  wait = (t: number) => {
    return new Promise((resolve) => {
      return setTimeout(resolve, t)
    })
  }

  createCall = (msg: ServerMessageData) => {
    console.log('Creating Call')
    // Reset connection attempts because we connected successfully
    this.state.connectAttempts = 0

    console.log('Creating RTCPeerConnection')

    const peerConnection = new RTCPeerConnection(this.state.rtcConfig)
    const sendChannel = peerConnection.createDataChannel('label', undefined)
    sendChannel.onopen = this.handleDataChannelOpen
    sendChannel.onmessage = this.handleDataChannelMessageReceived
    sendChannel.onerror = this.handleDataChannelError
    sendChannel.onclose = this.handleDataChannelClose
    peerConnection.ondatachannel = this.onDataChannel
    peerConnection.ontrack = this.onRemoteTrack

    this.setState(
      { peerConnection, sendChannel }
      // this.logStats
    )

    if (!msg.sdp) {
      console.log("WARNING: First message wasn't an SDP message!?")
    }

    peerConnection.onicecandidate = (event) => {
      // We have a candidate, send it to the remote party with the
      // same uuid
      if (event.candidate == null) {
        console.log('ICE Candidate was null, done')
        return
      }

      if (!this.state.wsCon) {
        throw new Error('WebSocked not found in ICE candidate handler!')
      }

      this.state.wsCon.send(JSON.stringify({ ice: event.candidate }))
    }

    console.log('Created peer connection for call, waiting for SDP')
  }

  handleDataChannelOpen = (event: Event) => {
    console.log('dataChannel.OnOpen', event)
  }

  handleDataChannelMessageReceived = (event: MessageEvent) => {
    console.log('dataChannel.OnMessage:', event, event.data.type)

    if (!this.state.sendChannel) {
      throw new Error('SendChannel not found in DataChannelMessageReceived handler!')
    }

    this.state.sendChannel.send('Hi! (from browser)')
  }

  handleDataChannelError = (error: unknown) => {
    console.log('dataChannel.OnError:', error)
  }

  handleDataChannelClose = (event: Event) => {
    console.log('dataChannel.OnClose', event)
  }

  onDataChannel(event: RTCDataChannelEvent) {
    console.log('Data channel created')
    const receiveChannel = event.channel
    receiveChannel.onopen = this.handleDataChannelOpen
    receiveChannel.onmessage = this.handleDataChannelMessageReceived
    receiveChannel.onerror = this.handleDataChannelError
    receiveChannel.onclose = this.handleDataChannelClose
  }

  onRemoteTrack = (event: RTCTrackEvent) => {
    const currentStream = event.streams[0] as MediaStream
    console.log('Got Stream!', currentStream)
    this.setState({ currentStream }, () => {
      console.log('Just Wrote the Current Stream')
    })
  }

  // SDP offer received from peer, set remote description and create an answer
  onIncomingSDP = async (sdp: RTCSessionDescriptionInit) => {
    if (!this.state.peerConnection) {
      throw new Error('PeerConnection not found in incomingSDP handler!')
    }

    await this.state.peerConnection.setRemoteDescription(sdp)
    if (sdp.type !== 'offer') {
      return
    }
    await this.onLocalDescription(await this.state.peerConnection.createAnswer())
  }

  // Local description was set, send it to peer
  onLocalDescription = async (desc: RTCSessionDescriptionInit) => {
    if (!this.state.peerConnection) {
      throw new Error('PeerConnection not found in onLocalDescription handler')
    }
    if (!this.state.wsCon) {
      throw new Error('WebSocket not found in onLocalDescription handler')
    }

    await this.state.peerConnection.setLocalDescription(desc)
    const sdp = { sdp: this.state.peerConnection.localDescription }
    this.state.wsCon.send(JSON.stringify(sdp))
  }

  // ICE candidate received from peer, add it to the peer connection
  onIncomingICE = async (ice: RTCIceCandidateInit) => {
    const candidate = new RTCIceCandidate(ice)

    try {
      if (!this.state.peerConnection) {
        throw new Error('PeerConnection not found in incomingICE handler!')
      }

      await this.state.peerConnection.addIceCandidate(candidate)
    } catch (error) {
      console.error(error)
    }
  }

  sensorChange = (sensor: string) => {
    if (this.state.wsCon) {
      this.state.wsCon.send('CONTROL ' + JSON.stringify({ cid: sensor }))
      this.setState({ currentSensor: sensor })
    }
  }

  bitrateChange = (bitrate: number) => {
    if (!isNaN(bitrate)) {
      return
    }
    this.setState({ bitrate }, () => {
      if (this.state.wsCon) {
        console.log('Setting Bitrate to', bitrate)
        this.state.wsCon.send('CONTROL ' + JSON.stringify({ bitrate: this.state.bitrate }))
      }
    })
  }
}

const webRTCContainer = new WebRTCContainer()
export default webRTCContainer
