import React, { Component } from 'react'
import PageWrapper from '../../helper/PageWrapper'
import camK1 from '../../img/k1.png'
import camK1_K2 from '../../img/k2_.png'
import camK2M from '../../img/k2m.png'
import camIp from '../../img/ip.png'
import camK2X from '../../img/k2x.png'
import plusIcon from '../../img/icons/plus.svg'
import i18n from '../../languages/i18n'
import AiswSpinner from '../helper/aiswSpinner/aiswSpinner'
import StaigeButton from '../../components/styles/Button'
import StaigeBadge from '../../components/styles/Badge'

import { CameraData } from '@soccerwatch/common'
import { CameraContainer } from '../../services/cameraContainer'
import { Link, RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router'
import { ContractContainer } from '../../services/contractContainer'

import './CameraList.scss'

type CameraListStateType = {
  cameras: CameraData[]
  loadingData: boolean
}

type CameraListPropsType = RouteComponentProps & {
  loadingData: boolean
  cameraContainer: CameraContainer
  contractContainer: ContractContainer
}

class CameraList extends Component<CameraListPropsType, CameraListStateType> {
  // Constructor
  constructor(props: CameraListPropsType) {
    super(props)
    this.state = {
      cameras: [],
      loadingData: true
    }
  }

  componentDidMount(): void {
    this.setState({
      cameras: this.props.cameraContainer.getCameraListForCurrentContract(),
      loadingData: this.props.cameraContainer.state.loadingData
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidUpdate(prevProps: CameraListPropsType) {
    if (
      this.props.cameraContainer.state.loadingData !== this.state.loadingData &&
      this.props.contractContainer.state.loadingData !== this.state.loadingData
    ) {
      this.setState({
        loadingData: this.props.cameraContainer.state.loadingData
      })
    }

    if (
      JSON.stringify(this.props.cameraContainer.getCameraListForCurrentContract()) !==
      JSON.stringify(this.state.cameras)
    ) {
      this.setState({
        cameras: this.props.cameraContainer.getCameraListForCurrentContract()
      })
    }
  }

  renderSingleCamera = (camera: CameraData) => {
    const cam = camera
    let cameraImg
    switch (cam.hardwarePlatform) {
      case 'ip':
      case 'qube':
        cameraImg = <img src={camIp} />
        break
      case 'k1':
        cameraImg = <img src={camK1} style={{ height: '180px' }} />
        break
      case 'k2m':
        cameraImg = <img src={camK2M} />
        break
      case 'k2x':
        cameraImg = <img src={camK2X} />
        break
      default:
        cameraImg = <img src={camK1_K2} />
        break
    }

    return (
      <Link to={`/cameramonitor/${cam.RowKey}`} className='content-cameraItem' key={cam.RowKey}>
        <div className='cameraItem-img2'></div>
        <div className='cameraItem-img'>
          <div className='saa'>
            <div className='bbb'>
              <svg
                width='327'
                height='102'
                viewBox='0 0 327 102'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <mask id='mask0_1608_1492' maskUnits='userSpaceOnUse' x='0' y='0' width='327' height='102'>
                  <rect y='0.517212' width='327' height='101.483' rx='3.75862' fill='#F6F6F6' />
                </mask>
                <g mask='url(#mask0_1608_1492)'>
                  <path
                    d='M65.7759 51.0201L22.2832 8.03448L9.39655 21.0912L39.8235 51.1394L9.69485 81.6049L22.7605 94.4828L65.7759 51.0201Z'
                    fill='#151315'
                  />
                  <path
                    d='M114.638 51.0201L71.1453 8.03448L58.2586 21.0912L88.6855 51.1394L58.5569 81.6049L71.6226 94.4828L114.638 51.0201Z'
                    fill='#151315'
                  />
                  <path
                    d='M163.5 51.0201L120.007 8.03448L107.121 21.0912L137.548 51.1394L107.419 81.6049L120.485 94.4828L163.5 51.0201Z'
                    fill='#151315'
                  />
                  <path
                    d='M261.224 51.0201L304.717 8.03448L317.603 21.0912L287.177 51.1394L317.305 81.6049L304.239 94.4828L261.224 51.0201Z'
                    fill='#151315'
                  />
                  <path
                    d='M212.362 51.0201L255.855 8.03448L268.741 21.0912L238.314 51.1394L268.443 81.6049L255.377 94.4828L212.362 51.0201Z'
                    fill='#151315'
                  />
                  <path
                    d='M163.5 51.0201L206.993 8.03448L219.879 21.0912L189.452 51.1394L219.581 81.6049L206.515 94.4828L163.5 51.0201Z'
                    fill='#151315'
                  />
                </g>
              </svg>
            </div>
          </div>

          <div className='xx'>{cameraImg}</div>
        </div>
        <div className='cameraItem-content'>
          <div className='content-id'>
            {cam.reachable ? (
              <StaigeBadge active title='online' color='aqua' />
            ) : (
              <StaigeBadge active title='offline' />
            )}
            <div className='id-text'>ID {cam.RowKey}</div>
          </div>
          <div className='content-info'>
            <div className='info-version'>{cam.hardwarePlatform}</div>
            <div className='info-place'>{cam.address}</div>
          </div>
          <div className='content-field'>{}</div>
        </div>
        <div className='cameraItem-action'>
          <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
            <mask id='mask0_693_13796' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'>
              <rect width='24' height='24' fill='#F6F6F6' />
            </mask>
            <g mask='url(#mask0_693_13796)'>
              <path d='M15 19L13.6 17.6L18.2 13H2V11H18.2L13.6 6.4L15 5L22 12L15 19Z' fill='#F6F6F6' />
            </g>
          </svg>
        </div>
      </Link>
    )
  }

  render() {
    if (this.state.loadingData) {
      return (
        <PageWrapper headerTitle={i18n.t('cameraList.headerTitle')}>
          <div style={{ width: '100%', height: '100%' }}>
            <AiswSpinner />
          </div>
        </PageWrapper>
      )
    }

    if (!this.state.loadingData && this.state.cameras.length === 0) {
      return (
        <PageWrapper headerTitle={i18n.t('cameraList.headerTitle')}>
          <div className='page-cameraList' style={{ height: '100%' }}>
            <div className='cameraList-noAllow'>
              <div className='noAllow-text'>
                {i18n.t('cameraList.noAllowText')}
                <br />
                {i18n.t('cameraList.noAllowText2')}
              </div>
              <StaigeButton
                label={i18n.t('cameraList.btnAddCam')}
                linkTo='/claim/camera'
                icon={plusIcon}
                iconMargin='0 0 0 -6px'
              />
            </div>
          </div>
        </PageWrapper>
      )
    }

    return (
      <PageWrapper headerTitle={i18n.t('cameraList.headerTitle')}>
        <div className='page-cameraList'>
          <div className='cameraList-cameraList'>
            <div className='cameraList-action'>
              <StaigeButton
                label={i18n.t('cameraList.btnAddCam')}
                icon={plusIcon}
                iconMargin='0 0 0 -4px'
                linkTo={'/claim/camera'}
                size='medium'
              />
            </div>
            <div className='cameraList-content'>
              {this.state.cameras.map((cam: CameraData) => {
                return this.renderSingleCamera(cam)
              })}
            </div>
          </div>
        </div>
      </PageWrapper>
    )
  }
}

export default withRouter(CameraList)
