import React, { Component } from 'react'

import { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router'

import matchfieldSVG from '../../../img/icons/matchfield.svg'
import mobileDeskSVG from '../../../img/icons/mobileDesk.svg'

import './ModeContainer.scss'
import moment from 'moment'
import i18n from '../../../languages/i18n'

type EventListContainerPropsType = RouteComponentProps & {
  videoState: { videoIsLive: boolean; videoIsEnd: boolean }
  handleTagMode: (state: undefined | 'remote' | 'live') => void
  videoInfo: any
}

type EventListContainerStateType = {
  timeEvents: boolean
}

class EventListContainer extends Component<EventListContainerPropsType, EventListContainerStateType> {
  // Constructor
  constructor(props: EventListContainerPropsType) {
    super(props)
    this.state = {
      timeEvents: true
    }
  }

  renderBox = (
    title: string,
    iconSrc: string,
    infoText: string,
    buttontitle: string,
    onClick: (state: undefined | 'live' | 'remote') => void,
    bottomInfoText?: string,
    disabled = false
  ) => {
    return (
      <div className='modeBox'>
        <div className='modeBox-top'>
          <div className='modeBox-top-inner'>
            <div className='inner-name'>{title}</div>
            <div className='inner-icon'>
              <img src={iconSrc} />
            </div>
          </div>
          <div className='modeBox-top-infoText'>{infoText}</div>
        </div>
        <div style={{ width: '100%' }}>
          <button className='staige-button-large' disabled={disabled} onClick={() => onClick(undefined)}>
            <div className='button-title'>
              <div className='title'>{buttontitle}</div>
            </div>
          </button>
          {bottomInfoText && bottomInfoText.length > 0 ? (
            <div className='modeBox-bottomInfoText'>{bottomInfoText}</div>
          ) : null}
        </div>
      </div>
    )
  }

  render() {
    let swcsID = '0'
    let startCamera = '00:00'

    if (this.props.videoInfo) {
      swcsID = this.props.videoInfo.swcsID
      startCamera = moment(this.props.videoInfo.expectedStartTime).add(-2, 'minutes').format('HH:mm')
    }

    return (
      <div className='modeContainert'>
        <div className='infoText'>{i18n.t('tagging.waitScreen.modeTitle')}</div>
        {this.renderBox(
          i18n.t('tagging.waitScreen.modeField.title'),
          matchfieldSVG,
          i18n.t('tagging.waitScreen.modeField.infoText'),
          i18n.t('tagging.waitScreen.modeField.btnText'),
          () => this.props.handleTagMode('live'),
          i18n.t('tagging.waitScreen.modeField.startInfo01') +
            swcsID +
            i18n.t('tagging.waitScreen.modeField.startInfo02') +
            startCamera +
            i18n.t('tagging.waitScreen.modeField.startInfo03'),
          !this.props.videoState.videoIsLive
        )}
        {this.renderBox(
          i18n.t('tagging.waitScreen.modeLive.title'),
          mobileDeskSVG,
          i18n.t('tagging.waitScreen.modeLive.infoText'),
          i18n.t('tagging.waitScreen.modeLive.btnText'),
          () => this.props.handleTagMode('remote')
          // !this.props.videoState.videoIsLive
        )}
      </div>
    )
  }
}

export default withRouter(EventListContainer)
