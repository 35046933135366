import { useEffect, DependencyList } from 'react'

export function useDebounceEffect(fn: () => void, waitTime: number, deps?: DependencyList) {
  useEffect(() => {
    const t = setTimeout(() => {
      // @ts-ignore
      fn(...deps)
    }, waitTime)

    return () => {
      clearTimeout(t)
    }
  }, deps)
}
