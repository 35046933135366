import React from 'react'
import { Link } from 'react-router-dom'

import './Button.scss'

type staigeButtonPropTypes = {
  icon?: string
  iconRight?: boolean
  iconMargin?: string
  linkTo?: string
  target?: string
  externLink?: boolean
  onClick?: any
  disabled?: boolean
  style?: 'primary' | 'secondary' | 'danger-secondary' | 'ghost' | 'white'
  size?: 'small' | 'medium' | 'large'
  round?: boolean
  label?: string
  fullWidth?: boolean
  tag?: any
}

type staigeButtonStateTypes = {
  nothing?: string
}

export default class StaigeButton extends React.Component<staigeButtonPropTypes, staigeButtonStateTypes> {
  render() {
    const {
      icon,
      linkTo,
      disabled,
      onClick,
      round,
      label,
      fullWidth,
      style,
      iconMargin,
      target,
      externLink,
      iconRight,
      size,
      tag
    } = this.props
    const roundCss = round ? 'staige-buttonRound' : ''
    const themeStyle = style !== 'primary' ? 'staige-button-' + style : ''
    const labelCss = label ? 'staige-button-withLabel' : ''
    let disabledCss = ''
    if (disabled) {
      if (style !== 'primary' && style !== undefined) {
        disabledCss = 'staige-' + style + '-buttonDisabled'
      } else {
        disabledCss = 'staige-buttonDisabled'
      }
    }

    return (
      <Link
        to={linkTo === undefined ? '' : externLink ? { pathname: linkTo } : linkTo}
        onClick={(event) => {
          if (disabled || linkTo === undefined) {
            event.preventDefault()
          }

          if (onClick && !disabled) {
            onClick()
          }
        }}
        className={`staige-button ${themeStyle} ${labelCss} ${roundCss} ${disabledCss} staige-button-${size} staige-button-withLabel-${size}`}
        style={fullWidth ? { width: '100%' } : {}}
        target={target}
      >
        <div className={`medium ${size}`}>
          {tag || null}
          {icon && !iconRight ? <img src={icon} style={{ margin: iconMargin }} /> : null}
          {label ? <div className='label'>{label}</div> : null}
          {icon && iconRight ? <img src={icon} style={{ margin: iconMargin }} /> : null}
        </div>
      </Link>
    )
  }
}
