import React, { useEffect } from 'react'

export default function SucccessCanclePage(props: { success: boolean; sessionId?: string }) {
  const channel = React.useMemo(() => new BroadcastChannel('payment'), [])

  useEffect(() => {
    const resMsg = props.success
      ? { success: true, sessionId: props.sessionId, close: true }
      : { success: false, close: true }
    channel.postMessage(resMsg)
    return channel.close
  }, [])

  return <div />
}
